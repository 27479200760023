import {
  faCheck,
  faExclamationTriangle,
  faPen,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  ButtonIcon,
  ButtonIconWithSmall,
  DataFieldColumn,
  FormWithValidation,
} from "_components";
import { Input } from "_components/Input";
import {
  CarteBancaireService,
  ChantierService,
  ContactService,
  MaterielsChantierService,
  PeriodeService,
  PersonnelService,
  StatutChantierService,
  StatutPeriodeChantierService,
  VehiculeService,
} from "_services";
import { App } from "App";
import moment from "moment";

import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const getArrayOfWeekByYear = (year) => {
  if (year == undefined) return [];
  let start = moment(new Date(year, 1, 1));
  let now = moment();

  let arrayWeeks = [];
  if (now.year() == start.year()) {
    for (let i = now.isoWeek() + 1; i < now.weeksInYear(); i++) {
      arrayWeeks.push(i);
    }
  } else {
    for (let i = 1; i < now.weeksInYear(); i++) {
      arrayWeeks.push(i);
    }
  }
  return arrayWeeks;
};

const getArrayOfYears = () => {
  let now = moment();
  let year = now.year();
  let arrayYears = [];
  for (let i = 0; i < 3; i++) {
    arrayYears.push(year + i);
  }
  return arrayYears;
};

const getColorByNow = (year, week) => {
  let now = moment();
  let start = moment(new Date(year, 1, 1));
  let color = "";
  let weekNow = now.isoWeek();
  if (now.year() > start.year()) {
    // color 1
    color = "red";
  } else if (now.year() == start.year()) {
    if (weekNow == week) {
      //color 2
      color = "green";
    } else if (weekNow > week) {
      // color 1
      color = "red";
    } else {
      // color 3
      color = "yellow";
    }
  } else {
    // color 3
    color = "yellow";
  }
  return color;
};

const BlocChantierColonne = (props) => {
  let [editing, setEditing] = useState(false);
  let [page, setPage] = useState(1);
  let [savedChantier, setSavedChantier] = useState(null);
  let [forceUpdateWeek, setForceUpdateWeek] = useState(() => null);
  let [forceUpdateTechs, setForceUpdateTechs] = useState(() => null);
  let [forceUpdateVehicules, setForceUpdateVehicules] = useState(() => null);
  let [forceUpdateMateriels, setForceUpdateMateriels] = useState(() => null);

  let [changeAffected, setChangeAffected] = useState(0);

  useEffect(() => {
    if (forceUpdateTechs != null) {
      forceUpdateTechs();
      forceUpdateVehicules();
      forceUpdateMateriels();
    }
  }, [changeAffected]);

  const rightPrep1 = App.RightsGuard?.current?.hasRight(
    "Chantiers",
    "Preparation chantier 1"
  );

  const rightPrep2 = App.RightsGuard?.current?.hasRight(
    "Chantiers",
    "Preparation chantier 2"
  );

  const rightMission = App.RightsGuard?.current?.hasRight(
    "Chantiers",
    "Preparation mission"
  );

  const rightMateriel = App.RightsGuard?.current?.hasRight(
    "Chantiers",
    "Preparation matériel"
  );

  const rightPeriode = App.RightsGuard?.current?.hasRight(
    "Chantiers",
    "Périodes"
  );

  const serviceGetContactsByClientId = () => {
    if (chantier?.affaire?.client?.id) {
      return ContactService.getByIdClient(chantier?.affaire?.client?.id);
    }
  };

  let [chantier, setChantier] = useState({});

  const handleGetAll = () => {
    ChantierService.getOneById(props.chantierId).then((e) =>
      setChantier(e.data)
    );
  };

  const update = (event) => {
    event.preventDefault();
    setEditing(false);
    ChantierService.put(chantier)
      .then(() => {
        handleGetAll();
      })
      .catch();
  };

  const handleChangeUpdate = (accessor, value) => {
    let chantierToUpdate = chantier;
    let chantierUpdated = { ...chantierToUpdate, [accessor]: value };
    if (accessor == "anneeChantier") {
      forceUpdateWeek();
      setChangeAffected(changeAffected + 1);
      handleChangeUpdate("semaineChantier", null);
    } else if (accessor == "semaineChantier") {
      setChangeAffected(changeAffected + 1);
    }
    setChantier(chantierUpdated);
  };

  const handleChangePeriodeUpdate = (accessor, value) => {
    var accessorSplit = accessor.split(".");

    let chantierToUpdate = chantier;

    let indexPeriode = chantierToUpdate.periodeChantiers.findIndex(
      (e) => e.id == accessorSplit[0]
    );
    let periodeToUpdate = chantierToUpdate.periodeChantiers[indexPeriode];
    let periodeUpdated = { ...periodeToUpdate, [accessorSplit[1]]: value };

    let chantierUpdated = { ...chantierToUpdate };
    chantierUpdated.periodeChantiers[indexPeriode] = periodeUpdated;

    setChantier(chantierUpdated);
  };

  const handleAddPeriode = () => {
    let id = -1;
    if (chantier?.periodeChantiers != null) {
      id = Math.min(
        ...chantier?.periodeChantiers?.[page - 1]?.map((e) => e.id - 1),
        -1
      );
    }
    let chantierToUpdate = chantier;

    let arrayPeriode = [...chantierToUpdate.periodeChantiers, { id: id }];

    let chantierUpdated = { ...chantierToUpdate };
    chantierUpdated.periodeChantiers = arrayPeriode;

    setChantier(chantierUpdated);
  };

  const handleResetChantier = () => {
    setChantier({ ...savedChantier });
  };

  const getTechniciensProxy = (params) => {
    return PersonnelService.getTechniciens({
      ...params,
      semaine: chantier?.semaineChantier,
      annee: chantier?.anneeChantier,
    });
  };
  const getVehiculesProxy = (params) => {
    return VehiculeService.getAllPourChantier({
      ...params,
      semaine: chantier?.semaineChantier,
      annee: chantier?.anneeChantier,
    });
  };

  const getMaterielsProxy = (params) => {
    return MaterielsChantierService.getAllPourChantier({
      ...params,
      semaine: chantier?.semaineChantier,
      annee: chantier?.anneeChantier,
    });
  };

  let buttonAddPeriode = (
    <ButtonIconWithSmall
      icon={faPlus}
      className="btn btn-success text-light ms-1"
      onClick={() => {
        handleAddPeriode();
        setEditing(true);
      }}
    />
  );

  const generatePeriodesBox = () => {
    let produitInterne = chantier?.affaire?.produitInterne?.designation;
    if (produitInterne == "ENDO" || produitInterne == "DO")
      return editing ? (
        <Box
          noPaddingBottom={true}
          header={boxHeader}
          rowClass="overflow-auto"
          boxClass="col-11"
          body={
            <table className="tableChantier table table-striped table-bordered table-sm">
              <thead></thead>
              <tbody>
                <tr>
                  <th className="text-nowrap ps-2">Périodes *</th>
                  <td>
                    <Input
                      value={chantier?.periodeChantiers?.[page - 1]?.periode}
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id + ".periode"
                      }
                      handleChange={handleChangePeriodeUpdate}
                      type="selectSearch"
                      service={PeriodeService.getAll}
                      optionFieldToDisplay={["designation"]}
                      valueFieldToDisplay={["designation"]}
                      required={true}
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Date *</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]?.datePeriode
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".datePeriode"
                      }
                      handleChange={handleChangePeriodeUpdate}
                      type="date"
                      required={true}
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>

                <tr>
                  <th className="text-nowrap ps-2">Arrivée chantier</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]
                          ?.arriveeChantier != null
                          ? chantier?.periodeChantiers?.[page - 1]
                              ?.arriveeChantier
                          : chantier?.periodeChantiers?.[
                              page - 1
                            ]?.datePeriode?.includes("T")
                          ? chantier?.periodeChantiers?.[page - 1]?.datePeriode
                          : chantier?.periodeChantiers?.[page - 1]
                              ?.datePeriode + "T00:00"
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".arriveeChantier"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="datetime-local"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Départ chantier</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]
                          ?.departChantier != null
                          ? chantier?.periodeChantiers?.[page - 1]
                              ?.departChantier
                          : chantier?.periodeChantiers?.[
                              page - 1
                            ]?.datePeriode?.includes("T")
                          ? chantier?.periodeChantiers?.[page - 1]?.datePeriode
                          : chantier?.periodeChantiers?.[page - 1]
                              ?.datePeriode + "T00:00"
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".departChantier"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="datetime-local"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Départ chantier(arf)</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]
                          ?.departChantierArf != null
                          ? chantier?.periodeChantiers?.[page - 1]
                              ?.departChantierArf
                          : chantier?.periodeChantiers?.[
                              page - 1
                            ]?.datePeriode?.includes("T")
                          ? chantier?.periodeChantiers?.[page - 1]?.datePeriode
                          : chantier?.periodeChantiers?.[page - 1]
                              ?.datePeriode + "T00:00"
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".departChantierArf"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="datetime-local"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps Global</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]?.tempsGlobal
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".tempsGlobal"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                      disabled={true}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps bureau</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]
                          ?.tempsBureauEtMateriel
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".tempsBureauEtMateriel"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                    />
                  </td>
                </tr>

                <tr>
                  <th className="text-nowrap ps-2">Temps chantier</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]?.tempsChantier
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".tempsChantier"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps déplacement</th>
                  <td>
                    {page == 1 || page == chantier?.periodeChantiers.length ? (
                      <Input
                        value={
                          chantier?.periodeChantiers?.[page - 1]
                            ?.tempsDeplacement
                        }
                        accessor={
                          chantier?.periodeChantiers?.[page - 1]?.id +
                          ".tempsDeplacement"
                        }
                        handleBlur={handleChangePeriodeUpdate}
                        handleChange={handleChangePeriodeUpdate}
                        type="decimal"
                      />
                    ) : (
                      <div></div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Repas</th>
                  <td>
                    <Input
                      value={{
                        value:
                          chantier?.periodeChantiers?.[page - 1]?.repasRealises,
                        id: chantier?.periodeChantiers?.[page - 1]
                          ?.repasRealises,
                      }}
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".repasRealises"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="selectSearch"
                      options={[
                        { id: 1, value: 1 },
                        { id: 2, value: 2 },
                      ]}
                      optionFieldToDisplay={["value"]}
                      valueFieldToDisplay={["value"]}
                      optionFieldToReturn={["value"]}
                    />
                  </td>
                </tr>

                <tr>
                  <th className="text-nowrap ps-2">Sondages réalisés</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]?.quantiteRealisee
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".quantiteRealisee"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Statut</th>
                  <td>
                    <Input
                      value={chantier?.periodeChantiers?.[page - 1]?.statut}
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id + ".statut"
                      }
                      handleChange={handleChangePeriodeUpdate}
                      type="selectSearch"
                      service={StatutPeriodeChantierService.getAll}
                      optionFieldToDisplay={["designation"]}
                      valueFieldToDisplay={["designation"]}
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Commentaires</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]?.commentaire
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".commentaire"
                      }
                      handleChange={handleChangePeriodeUpdate}
                      type="text"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>

                <tr>
                  <th className="text-nowrap ps-2">Jour/Nuit</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.periode?.isNuit
                      ? "Nuit"
                      : "Jour"}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Reliquat</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.periode?.reliquat}
                  </td>
                </tr>
              </tbody>
            </table>
          }
        ></Box>
      ) : (
        <Box
          noPaddingBottom={true}
          header={boxHeader}
          rowClass="overflow-auto"
          boxClass="col-11"
          body={
            <table className="tableChantier table table-striped table-bordered table-sm">
              <thead></thead>
              <tbody>
                <tr>
                  <th className="text-nowrap ps-2">Périodes</th>
                  <td>
                    {
                      chantier?.periodeChantiers?.[page - 1]?.periode
                        ?.designation
                    }
                  </td>
                </tr>

                <tr>
                  <th className="text-nowrap ps-2">Arrivée chantier</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.arriveeChantier
                      ? new Date(
                          chantier?.periodeChantiers?.[
                            page - 1
                          ]?.arriveeChantier
                        ).toLocaleString("fr-FR")
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Départ chantier</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.departChantier
                      ? new Date(
                          chantier?.periodeChantiers?.[page - 1]?.departChantier
                        ).toLocaleString("fr-FR")
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Départ chantier (arf)</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.departChantierArf
                      ? new Date(
                          chantier?.periodeChantiers?.[
                            page - 1
                          ]?.departChantierArf
                        ).toLocaleString("fr-FR")
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps Global</th>
                  <td>{chantier?.periodeChantiers?.[page - 1]?.tempsGlobal}</td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps bureau</th>
                  <td>
                    {
                      chantier?.periodeChantiers?.[page - 1]
                        ?.tempsBureauEtMateriel
                    }
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps chantier</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.tempsChantier}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps déplacement</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.tempsDeplacement}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Sondages réalisés</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.quantiteRealisee}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Repas</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.repasRealises}
                  </td>
                </tr>

                <tr>
                  <th className="text-nowrap ps-2">Statut</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.statut
                      ?.designation ?? "-"}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Commentaires</th>
                  <td>{chantier?.periodeChantiers?.[page - 1]?.commentaire}</td>
                </tr>

                <tr>
                  <th className="text-nowrap ps-2">Jour/Nuit</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.periode?.isNuit
                      ? "Nuit"
                      : "Jour"}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Reliquat</th>
                  {<td>{chantier?.periodeChantiers?.[page - 1]?.reliquat}</td>}
                </tr>
              </tbody>
            </table>
          }
        ></Box>
      );
    else if (produitInterne == "3CO") {
      return editing ? (
        <Box
          noPaddingBottom={true}
          header={boxHeader}
          rowClass="overflow-auto"
          boxClass="col-11"
          body={
            <table className="tableChantier table table-striped table-bordered table-sm">
              <thead></thead>
              <tbody>
                <tr>
                  <th className="text-nowrap ps-2">Périodes *</th>
                  <td>
                    <Input
                      value={chantier?.periodeChantiers?.[page - 1]?.periode}
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id + ".periode"
                      }
                      handleChange={handleChangePeriodeUpdate}
                      type="selectSearch"
                      service={PeriodeService.getAll}
                      optionFieldToDisplay={["designation"]}
                      valueFieldToDisplay={["designation"]}
                      required={true}
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Date *</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]?.datePeriode
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".datePeriode"
                      }
                      handleChange={handleChangePeriodeUpdate}
                      type="date"
                      required={true}
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>

                <tr>
                  <th className="text-nowrap ps-2">Arrivée chantier</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]
                          ?.arriveeChantier != null
                          ? chantier?.periodeChantiers?.[page - 1]
                              ?.arriveeChantier
                          : chantier?.periodeChantiers?.[
                              page - 1
                            ]?.datePeriode?.includes("T")
                          ? chantier?.periodeChantiers?.[page - 1]?.datePeriode
                          : chantier?.periodeChantiers?.[page - 1]
                              ?.datePeriode + "T00:00"
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".arriveeChantier"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="datetime-local"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Départ chantier</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]
                          ?.departChantier != null
                          ? chantier?.periodeChantiers?.[page - 1]
                              ?.departChantier
                          : chantier?.periodeChantiers?.[
                              page - 1
                            ]?.datePeriode?.includes("T")
                          ? chantier?.periodeChantiers?.[page - 1]?.datePeriode
                          : chantier?.periodeChantiers?.[page - 1]
                              ?.datePeriode + "T00:00"
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".departChantier"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="datetime-local"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Départ chantier(arf)</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]
                          ?.departChantierArf != null
                          ? chantier?.periodeChantiers?.[page - 1]
                              ?.departChantierArf
                          : chantier?.periodeChantiers?.[
                              page - 1
                            ]?.datePeriode?.includes("T")
                          ? chantier?.periodeChantiers?.[page - 1]?.datePeriode
                          : chantier?.periodeChantiers?.[page - 1]
                              ?.datePeriode + "T00:00"
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".departChantierArf"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="datetime-local"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps Global</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]?.tempsGlobal
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".tempsGlobal"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                      disabled={true}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps bureau</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]
                          ?.tempsBureauEtMateriel
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".tempsBureauEtMateriel"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                    />
                  </td>
                </tr>

                <tr>
                  <th className="text-nowrap ps-2">Temps chantier</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]?.tempsChantier
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".tempsChantier"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps déplacement</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]?.tempsDeplacement
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".tempsDeplacement"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Déplacement nuit</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]?.isDeplaNuit
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".isDeplaNuit"
                      }
                      handleChange={handleChangePeriodeUpdate}
                      type="checkbox"
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Repas</th>

                  <td>
                    <Input
                      value={{
                        value:
                          chantier?.periodeChantiers?.[page - 1]?.repasRealises,
                        id: chantier?.periodeChantiers?.[page - 1]
                          ?.repasRealises,
                      }}
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".repasRealises"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="selectSearch"
                      options={[
                        { id: 1, value: 1 },
                        { id: 2, value: 2 },
                      ]}
                      optionFieldToDisplay={["value"]}
                      valueFieldToDisplay={["value"]}
                      optionFieldToReturn={["value"]}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">
                    Linéaire collecteur inspecté
                  </th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]
                          ?.lineaireCollecteurInspecte
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".lineaireCollecteurInspecte"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">
                    Nb branchements inspectés
                  </th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]
                          ?.nombreBranchementInspecte
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".nombreBranchementInspecte"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Nb contrôles compactage</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]
                          ?.nombreControleCompactageRealise
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".nombreControleCompactageRealise"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">
                    linéaire collecteur testé
                  </th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]
                          ?.lineaireCollecteurTeste
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".lineaireCollecteurTeste"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Statut</th>
                  <td>
                    <Input
                      value={chantier?.periodeChantiers?.[page - 1]?.statut}
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id + ".statut"
                      }
                      handleChange={handleChangePeriodeUpdate}
                      type="selectSearch"
                      service={StatutPeriodeChantierService.getAll}
                      optionFieldToDisplay={["designation"]}
                      valueFieldToDisplay={["designation"]}
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Commentaires</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]?.commentaire
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".commentaire"
                      }
                      handleChange={handleChangePeriodeUpdate}
                      type="text"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>

                <tr>
                  <th className="text-nowrap ps-2">Jour/Nuit</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.periode?.isNuit
                      ? "Nuit"
                      : "Jour"}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Reliquat</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.periode?.reliquat}
                  </td>
                </tr>
              </tbody>
            </table>
          }
        ></Box>
      ) : (
        <Box
          noPaddingBottom={true}
          header={boxHeader}
          rowClass="overflow-auto"
          boxClass="col-11"
          body={
            <table className="tableChantier table table-striped table-bordered table-sm">
              <thead></thead>
              <tbody>
                <tr>
                  <th className="text-nowrap ps-2">Périodes</th>
                  <td>
                    {
                      chantier?.periodeChantiers?.[page - 1]?.periode
                        ?.designation
                    }
                  </td>
                </tr>

                <tr>
                  <th className="text-nowrap ps-2">Arrivée chantier</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.arriveeChantier
                      ? new Date(
                          chantier?.periodeChantiers?.[
                            page - 1
                          ]?.arriveeChantier
                        ).toLocaleString("fr-FR")
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Départ chantier</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.departChantier
                      ? new Date(
                          chantier?.periodeChantiers?.[page - 1]?.departChantier
                        ).toLocaleString("fr-FR")
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps Global</th>
                  <td>{chantier?.periodeChantiers?.[page - 1]?.tempsGlobal}</td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps bureau</th>
                  <td>
                    {
                      chantier?.periodeChantiers?.[page - 1]
                        ?.tempsBureauEtMateriel
                    }
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps chantier</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.tempsChantier}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps déplacement</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.tempsDeplacement}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Déplacement nuit</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.isDeplaNuit
                      ? "Nuit"
                      : "Jour"}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Repas</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.repasRealises}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">
                    Linéire collecteur inspecté
                  </th>
                  <td>
                    {
                      chantier?.periodeChantiers?.[page - 1]
                        ?.lineaireCollecteurInspecte
                    }
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">
                    Nb branchements inspectés
                  </th>
                  <td>
                    {
                      chantier?.periodeChantiers?.[page - 1]
                        ?.nombreBranchementInspecte
                    }
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Nb contrôles compactage</th>
                  <td>
                    {
                      chantier?.periodeChantiers?.[page - 1]
                        ?.nombreControleCompactageRealise
                    }
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Linéire collecteur testé</th>
                  <td>
                    {
                      chantier?.periodeChantiers?.[page - 1]
                        ?.lineaireCollecteurTeste
                    }
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Statut</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.statut
                      ?.designation ?? "-"}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Commentaires</th>
                  <td>{chantier?.periodeChantiers?.[page - 1]?.commentaire}</td>
                </tr>

                <tr>
                  <th className="text-nowrap ps-2">Jour/Nuit</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.periode?.isNuit
                      ? "Nuit"
                      : "Jour"}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Reliquat</th>
                  {<td>{chantier?.periodeChantiers?.[page - 1]?.reliquat}</td>}
                </tr>
              </tbody>
            </table>
          }
        ></Box>
      );
    } else if (
      produitInterne == "ETU" ||
      produitInterne == "PHY" ||
      produitInterne == "M3S" ||
      produitInterne == "DIG" ||
      produitInterne == "ECG"
    ) {
      return editing ? (
        <Box
          noPaddingBottom={true}
          header={boxHeader}
          rowClass="overflow-auto"
          boxClass="col-11"
          body={
            <table className="tableChantier table table-striped table-bordered table-sm">
              <thead></thead>
              <tbody>
                <tr>
                  <th className="text-nowrap ps-2">Périodes *</th>
                  <td>
                    <Input
                      value={chantier?.periodeChantiers?.[page - 1]?.periode}
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id + ".periode"
                      }
                      handleChange={handleChangePeriodeUpdate}
                      type="selectSearch"
                      service={PeriodeService.getAll}
                      optionFieldToDisplay={["designation"]}
                      valueFieldToDisplay={["designation"]}
                      required={true}
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Date *</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]?.datePeriode
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".datePeriode"
                      }
                      handleChange={handleChangePeriodeUpdate}
                      type="date"
                      required={true}
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>

                <tr>
                  <th className="text-nowrap ps-2">Arrivée chantier</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]
                          ?.arriveeChantier != null
                          ? chantier?.periodeChantiers?.[page - 1]
                              ?.arriveeChantier
                          : chantier?.periodeChantiers?.[
                              page - 1
                            ]?.datePeriode?.includes("T")
                          ? chantier?.periodeChantiers?.[page - 1]?.datePeriode
                          : chantier?.periodeChantiers?.[page - 1]
                              ?.datePeriode + "T00:00"
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".arriveeChantier"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="datetime-local"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Départ chantier</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]
                          ?.departChantier != null
                          ? chantier?.periodeChantiers?.[page - 1]
                              ?.departChantier
                          : chantier?.periodeChantiers?.[
                              page - 1
                            ]?.datePeriode?.includes("T")
                          ? chantier?.periodeChantiers?.[page - 1]?.datePeriode
                          : chantier?.periodeChantiers?.[page - 1]
                              ?.datePeriode + "T00:00"
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".departChantier"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="datetime-local"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Départ chantier(arf)</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]
                          ?.departChantierArf != null
                          ? chantier?.periodeChantiers?.[page - 1]
                              ?.departChantierArf
                          : chantier?.periodeChantiers?.[
                              page - 1
                            ]?.datePeriode?.includes("T")
                          ? chantier?.periodeChantiers?.[page - 1]?.datePeriode
                          : chantier?.periodeChantiers?.[page - 1]
                              ?.datePeriode + "T00:00"
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".departChantierArf"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="datetime-local"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps Global</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]?.tempsGlobal
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".tempsGlobal"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                      disabled={true}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps bureau</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]
                          ?.tempsBureauEtMateriel
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".tempsBureauEtMateriel"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                    />
                  </td>
                </tr>

                <tr>
                  <th className="text-nowrap ps-2">Temps chantier</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]?.tempsChantier
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".tempsChantier"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps déplacement</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]?.tempsDeplacement
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".tempsDeplacement"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Déplacement nuit</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]?.isDeplaNuit
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".isDeplaNuit"
                      }
                      handleChange={handleChangePeriodeUpdate}
                      type="checkbox"
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Repas</th>
                  <td>
                    <Input
                      value={{
                        value:
                          chantier?.periodeChantiers?.[page - 1]?.repasRealises,
                        id: chantier?.periodeChantiers?.[page - 1]
                          ?.repasRealises,
                      }}
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".repasRealises"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="selectSearch"
                      options={[
                        { id: 1, value: 1 },
                        { id: 2, value: 2 },
                      ]}
                      optionFieldToDisplay={["value"]}
                      valueFieldToDisplay={["value"]}
                      optionFieldToReturn={["value"]}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Nombre essais</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]?.nombreEssai
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".nombreEssai"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Mètre linéaire</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]?.metreLineaire
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".metreLineaire"
                      }
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Statut</th>
                  <td>
                    <Input
                      value={chantier?.periodeChantiers?.[page - 1]?.statut}
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id + ".statut"
                      }
                      handleChange={handleChangePeriodeUpdate}
                      type="selectSearch"
                      service={StatutPeriodeChantierService.getAll}
                      optionFieldToDisplay={["designation"]}
                      valueFieldToDisplay={["designation"]}
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Commentaires</th>
                  <td>
                    <Input
                      value={
                        chantier?.periodeChantiers?.[page - 1]?.commentaire
                      }
                      accessor={
                        chantier?.periodeChantiers?.[page - 1]?.id +
                        ".commentaire"
                      }
                      handleChange={handleChangePeriodeUpdate}
                      type="text"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                </tr>

                <tr>
                  <th className="text-nowrap ps-2">Jour/Nuit</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.periode?.isNuit
                      ? "Nuit"
                      : "Jour"}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Reliquat</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.periode?.reliquat}
                  </td>
                </tr>
              </tbody>
            </table>
          }
        ></Box>
      ) : (
        <Box
          noPaddingBottom={true}
          header={boxHeader}
          rowClass="overflow-auto"
          boxClass="col-11"
          body={
            <table className="tableChantier table table-striped table-bordered table-sm">
              <thead></thead>
              <tbody>
                <tr>
                  <th className="text-nowrap ps-2">Périodes</th>
                  <td>
                    {
                      chantier?.periodeChantiers?.[page - 1]?.periode
                        ?.designation
                    }
                  </td>
                </tr>

                <tr>
                  <th className="text-nowrap ps-2">Arrivée chantier</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.arriveeChantier
                      ? new Date(
                          chantier?.periodeChantiers?.[
                            page - 1
                          ]?.arriveeChantier
                        ).toLocaleString("fr-FR")
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Départ chantier</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.departChantier
                      ? new Date(
                          chantier?.periodeChantiers?.[page - 1]?.departChantier
                        ).toLocaleString("fr-FR")
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps Global</th>
                  <td>{chantier?.periodeChantiers?.[page - 1]?.tempsGlobal}</td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps bureau</th>
                  <td>
                    {
                      chantier?.periodeChantiers?.[page - 1]
                        ?.tempsBureauEtMateriel
                    }
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps chantier</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.tempsChantier}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Temps déplacement</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.tempsDeplacement}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Déplacement nuit</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.isDeplaNuit
                      ? "Nuit"
                      : "Jour"}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Repas</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.repasRealises}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Nombre essais</th>
                  <td>{chantier?.periodeChantiers?.[page - 1]?.nombreEssai}</td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Mètre linéaire</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.metreLineaire}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Statut</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.statut
                      ?.designation ?? "-"}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Commentaires</th>
                  <td>{chantier?.periodeChantiers?.[page - 1]?.commentaire}</td>
                </tr>

                <tr>
                  <th className="text-nowrap ps-2">Jour/Nuit</th>
                  <td>
                    {chantier?.periodeChantiers?.[page - 1]?.periode?.isNuit
                      ? "Nuit"
                      : "Jour"}
                  </td>
                </tr>
                <tr>
                  <th className="text-nowrap ps-2">Reliquat</th>
                  {<td>{chantier?.periodeChantiers?.[page - 1]?.reliquat}</td>}
                </tr>
              </tbody>
            </table>
          }
        ></Box>
      );
    }
    return <></>;
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  let boxHeader = (
    <>
      <div className=" align-self-center py-2 elementHeaderChantierSynthese1">
        <div className="sousElementHeaderChantierSynthese1">
          <div
            style={{
              width: "20px",
              height: "20px",
              borderRadius: "10px",
              backgroundColor: getColorByNow(
                chantier?.anneeChantier,
                chantier?.semaineChantier
              ),
              marginRight: "5px",
              flexShrink: "0",
            }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>{chantier?.reference}/</div>
            <div>{chantier?.affaire?.nomAffaire}/</div>
            <div>{chantier?.affaire?.produitInterne.designation}</div>
          </div>
        </div>
      </div>
      <div className=" align-self-center text-center py-2 text-warning elementHeaderChantierSynthese2">
        {chantier?.hasHeureNotValidated ? (
          <>
            <FontAwesomeIcon icon={faExclamationTriangle} className="mx-2" />
            Heures non validées
            <br />
          </>
        ) : null}
      </div>
      <div className="elementHeaderChantierSynthese3 text-end py-2">
        <ButtonIcon
          icon={faPlus}
          className="btn btn-success text-light ms-1"
          style={!editing ? { display: "none" } : { width: "42px" }}
          onClick={() => {
            handleAddPeriode();
            setEditing(true);
            setPage(chantier?.periodeChantiers?.length + 1);
          }}
        />
        <ButtonIcon
          icon={faCheck}
          className="btn btn-success text-light ms-1"
          style={!editing ? { display: "none" } : { width: "42px" }}
          type="submit"
        ></ButtonIcon>
        <ButtonIcon
          icon={faTimes}
          className="btn btn-danger text-light ms-1"
          onClick={() => {
            setEditing(false);
            handleResetChantier();
          }}
          style={!editing ? { display: "none" } : { width: "42px" }}
        ></ButtonIcon>
        <ButtonIcon
          icon={faPen}
          className="btn btn-secondary text-light ms-1"
          onClick={() => {
            setEditing(true);
            setSavedChantier({ ...chantier });
          }}
          style={editing ? { display: "none" } : { width: "42px" }}
        ></ButtonIcon>
      </div>
    </>
  );

  let box = editing ? (
    <Box
      header={boxHeader}
      rowClass="overflow-auto "
      boxClass="col-11"
      body={
        <div className="row noWrap">
          <div className="col">
            <div>
              <Input
                label="Année"
                value={chantier?.anneeChantier}
                accessor="anneeChantier"
                handleBlur={handleChangeUpdate}
                type="selectSearch"
                options={getArrayOfYears()}
                disabled={rightPrep1 != "RW"}
              />
            </div>
            <div>
              <Input
                label="Semaine"
                value={chantier?.semaineChantier}
                accessor="semaineChantier"
                handleBlur={handleChangeUpdate}
                type="selectSearch"
                options={getArrayOfWeekByYear(chantier?.anneeChantier)}
                setForceUpdateFunction={(callback) =>
                  setForceUpdateWeek(() => callback)
                }
                disabled={rightPrep1 != "RW"}
              />
            </div>
            <div>
              <Input
                label="Date début"
                value={chantier?.dateDebutChantier}
                accessor="dateDebutChantier"
                handleChange={handleChangeUpdate}
                type="date"
                required={true}
                disabled={rightPrep1 != "RW"}
              />
            </div>
            <div>
              <Input
                label="Heure Arrivée bureau"
                value={chantier?.heureArriveeBureau}
                accessor="heureArriveeBureau"
                handleChange={handleChangeUpdate}
                type="time"
                disabled={rightMission != "RW"}
              />
            </div>
            <div>
              <Input
                label="HeureRDV"
                value={chantier?.heureRDV}
                accessor="heureRDV"
                handleChange={handleChangeUpdate}
                type="time"
                disabled={rightPrep2 != "RW"}
              />
            </div>
            <div>
              <Input
                label="Lieu RDV"
                value={chantier?.lieuRDV}
                accessor="lieuRDV"
                handleChange={handleChangeUpdate}
                type="text"
                disabled={rightPrep2 != "RW"}
              />
            </div>
            <div>
              <Input
                label="Statut"
                value={chantier?.statut}
                accessor="statut"
                handleBlur={handleChangeUpdate}
                type="selectSearch"
                service={StatutChantierService.getAll}
                optionFieldToDisplay={"designation"}
                valueFieldToDisplay={"designation"}
                disabled={rightPrep2 != "RW"}
              />
            </div>
            {chantier?.statut?.designation == "Annulé" ? (
              <div>
                <Input
                  label="Motif annulation"
                  value={chantier?.motifAnnulation}
                  accessor="motifAnnulation"
                  handleChange={handleChangeUpdate}
                  type="selectSearch"
                  service={MotifsChantierAnnulationService.getAll}
                  optionFieldToDisplay={"designation"}
                  valueFieldToDisplay={"designation"}
                  required={true}
                  disabled={rightPrep2 != "RW"}
                />
              </div>
            ) : null}
          </div>

          <div className="col">
            <div>
              <Input
                label="Contact"
                value={chantier?.contact}
                accessor="contact"
                handleChange={handleChangeUpdate}
                type="selectSearch"
                service={serviceGetContactsByClientId}
                optionFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                valueFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                disabled={rightPrep2 != "RW"}
              />
            </div>
            <div>
              <Input
                label="PP"
                value={chantier?.planPrevention}
                accessor="planPrevention"
                handleChange={handleChangeUpdate}
                type="checkbox"
                disabled={rightPrep2 != "RW"}
              />
            </div>

            <div>
              <Input
                label="Labo"
                value={chantier?.labo}
                accessor="labo"
                handleChange={handleChangeUpdate}
                type="checkbox"
                disabled={rightPrep2 != "RW"}
              />
            </div>
            <div>
              <Input
                label="Lieu chantier"
                value={chantier?.lieuChantier}
                accessor="lieuChantier"
                handleChange={handleChangeUpdate}
                type="address"
                disabled={rightPrep2 != "RW"}
                intituleLabel="Lieu chantier"
              />
            </div>
            <div>
              <Input
                label="lien GPS"
                value={chantier?.lienGPS}
                accessor="lienGPS"
                handleChange={handleChangeUpdate}
                type="text"
                disabled={rightPrep2 != "RW"}
              />
            </div>
          </div>
          <div className="col">
            <div>
              <Input
                label="Nuits prévues"
                value={chantier?.nbNuitsPrev}
                accessor="nbNuitsPrev"
                handleBlur={handleChangeUpdate}
                type="decimal"
                numberOfDecimals={2}
                disabled={rightMission != "RW"}
              />
            </div>
            <div>
              <Input
                label="Jours prévus"
                value={chantier?.nbJoursPrev}
                accessor="nbJoursPrev"
                handleBlur={handleChangeUpdate}
                type="decimal"
                numberOfDecimals={2}
                disabled={rightMission != "RW"}
              />
            </div>
            <div>
              <Input
                label="Nombre de sondages prévus"
                value={chantier?.nbSondagesPrev}
                accessor="nbSondagesPrev"
                handleBlur={handleChangeUpdate}
                type="decimal"
                numberOfDecimals={2}
                disabled={rightMission != "RW"}
              />
            </div>
          </div>
          <div className="col">
            <Input
              label="Techniciens"
              value={chantier?.techniciens}
              accessor="techniciens"
              handleChange={handleChangeUpdate}
              type="multipleSelectSearch"
              service={getTechniciensProxy}
              optionFieldToDisplay={["nom", "prenom"]}
              valueFieldToDisplay={["nom", "prenom"]}
              disabled={rightMission != "RW"}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={props.key}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.occupe ? (
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className="me-2 text-danger"
                    />
                  ) : null}
                  {option.nom + " " + option.prenom}
                </li>
              )}
              setForceUpdateFunction={(callback) =>
                setForceUpdateTechs(() => callback)
              }
            />
            <Input
              label="Vehicules"
              value={chantier?.vehicules}
              accessor="vehicules"
              handleChange={handleChangeUpdate}
              type="multipleSelectSearch"
              service={getVehiculesProxy}
              optionFieldToDisplay={"designation"}
              valueFieldToDisplay={"designation"}
              disabled={rightMateriel != "RW"}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={props.key}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.occupe ? (
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className="me-2 text-danger"
                    />
                  ) : null}
                  {(option.produitInterne?.designation ?? "") +
                    " | " +
                    option.designation +
                    " (" +
                    option.nom +
                    ")"}
                </li>
              )}
              setForceUpdateFunction={(callback) =>
                setForceUpdateVehicules(() => callback)
              }
            />
            <div>
              <Input
                label="Preparateurs"
                value={chantier?.preparateurs}
                accessor="preparateurs"
                handleChange={handleChangeUpdate}
                type="multipleSelectSearch"
                service={PersonnelService.getAll}
                optionFieldToDisplay={["nom", "prenom"]}
                valueFieldToDisplay={["nom", "prenom"]}
                disabled={rightMateriel != "RW"}
              />
            </div>
            <div>
              <Input
                label="Materiels"
                value={chantier?.materielsChantier}
                accessor="materielsChantier"
                handleChange={handleChangeUpdate}
                type="multipleSelectSearch"
                service={getMaterielsProxy}
                optionFieldToDisplay={[
                  "produitInterne.designation",
                  "designation",
                  "num_Serie",
                ]}
                valueFieldToDisplay={["designation", "num_Serie"]}
                disabled={rightMateriel != "RW"}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.occupe ? (
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="me-2 text-danger"
                      />
                    ) : null}
                    {(option.produitInterne?.designation ?? "") +
                      " | " +
                      option.designation +
                      (option.num_Serie ? " (" + option.num_Serie + ")" : "")}
                  </li>
                )}
                setForceUpdateFunction={(callback) =>
                  setForceUpdateMateriels(() => callback)
                }
              />
            </div>
          </div>
          <div className="col">
            <Input
              label="Commentaire"
              value={chantier?.commentaire}
              accessor="commentaire"
              handleChange={handleChangeUpdate}
              type="textArea"
            />
          </div>
          <div className="col">
            <Input
              label="Zone réservation"
              value={chantier?.zoneReservation}
              accessor="zoneReservation"
              handleChange={handleChangeUpdate}
              type="text"
            />
            <Input
              label="Période réservation"
              value={chantier?.trancheDateReservation}
              accessor="trancheDateReservation"
              handleChange={handleChangeUpdate}
              type="period"
            />
            <Input
              label="Proposition hôtel"
              value={chantier?.propositionHotel}
              accessor="propositionHotel"
              handleChange={handleChangeUpdate}
              type="text"
            />
            <Input
              label="Localisation hôtel"
              value={chantier?.localisationHotel}
              accessor="localisationHotel"
              handleChange={handleChangeUpdate}
              type="text"
            />
            <Input
              label="Nom hôtel"
              value={chantier?.nomHotel}
              accessor="nomHotel"
              handleChange={handleChangeUpdate}
              type="text"
            />
            <Input
              label="Prix"
              value={chantier?.prix}
              accessor="prix"
              handleChange={handleChangeUpdate}
              handleBlur={handleChangeUpdate}
              type="decimal"
            />
            <Input
              label="Carte bancaire"
              value={chantier?.cb}
              accessor="cb"
              handleChange={handleChangeUpdate}
              service={CarteBancaireService.getAll}
              type="selectSearch"
              optionFieldToDisplay={["name"]}
              valueFieldToDisplay={["name"]}
            />
            <Input
              label="Montant remboursé"
              value={chantier?.montantRembourse}
              accessor="montantRembourse"
              handleChange={handleChangeUpdate}
              handleBlur={handleChangeUpdate}
              type="decimal"
            />
            <Input
              label="Petit déjeuner"
              value={chantier?.petitDej}
              accessor="petitDej"
              handleChange={handleChangeUpdate}
              type="checkbox"
            />
            <Input
              label="Commentaire hôtel"
              value={chantier?.commentaireHotel}
              accessor="commentaireHotel"
              handleChange={handleChangeUpdate}
              type="textArea"
            />
          </div>
        </div>
      }
    ></Box>
  ) : (
    <Box
      header={boxHeader}
      rowClass="overflow-auto"
      boxClass="widthMobileChantier"
      body={
        <div className="row noWrap">
          <div className="col">
            <div>
              <DataFieldColumn
                label="Année"
                type="text"
                value={chantier?.anneeChantier}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Semaine"
                type="text"
                value={chantier?.semaineChantier}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Date début"
                type="text"
                value={chantier?.dateDebutChantier}
                functionAppliedToValue={(value) =>
                  value ? new Date(value).toLocaleDateString("fr-FR") : "-"
                }
              />
            </div>
            <div>
              <DataFieldColumn
                label="Heure Bureau"
                type="text"
                value={chantier?.heureArriveeBureau}
              />
            </div>
            <div>
              <DataFieldColumn
                label="HeureRDV"
                type="text"
                value={chantier?.heureRDV}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Lieu RDV"
                type="text"
                value={chantier?.lieuRDV}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Statut"
                type="text"
                value={chantier?.statut?.designation}
              />
            </div>
            {chantier?.statut?.designation == "Annulé" ? (
              <div>
                <DataFieldColumn
                  label="Motif annulation"
                  value={chantier?.motifAnnulation?.designation}
                />
              </div>
            ) : null}
          </div>
          <div className="col">
            <div>
              <DataFieldColumn
                label="Contact"
                type="text"
                value={chantier?.contact}
                functionAppliedToValue={(value) => {
                  return value ? (
                    <a
                      href={"/contacts/" + value.id}
                      target="_blank"
                      data-text={value.nom_Contact + " " + value.prenom_Contact}
                      className="fixWidthHover"
                    >
                      {value.nom_Contact + " " + value.prenom_Contact}
                    </a>
                  ) : (
                    "-"
                  );
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Téléphone"
                type="text"
                value={chantier?.contact}
                functionAppliedToValue={(value) => {
                  return value ? value.portable : "-";
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="PP"
                type="text"
                value={chantier?.planPrevention}
                functionAppliedToValue={(value) => {
                  return value ? "Oui" : "Non";
                }}
              />
            </div>

            <div>
              <DataFieldColumn
                label="Labo"
                type="text"
                value={chantier?.labo}
                functionAppliedToValue={(value) => {
                  return value ? "Oui" : "Non";
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Lieu chantier"
                type="text"
                value={chantier?.lieuChantier}
                functionAppliedToValue={(value) => {
                  return value ? (
                    <div>
                      <div>{value.intitule}</div>
                      <div>{value.voie}</div>
                      <div>{value.complement}</div>
                      <div>{value.ville + ", " + value.cp}</div>
                      <div>{value.pays?.nom_fr}</div>
                    </div>
                  ) : (
                    "-"
                  );
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Lien GPS"
                type="text"
                value={chantier?.lienGPS}
              />
            </div>
          </div>
          <div className="col">
            <div>
              <DataFieldColumn
                label="nuitprev"
                type="text"
                value={chantier?.nbNuitsPrev}
              />
            </div>
            <div>
              <DataFieldColumn
                label="jourprev"
                type="text"
                value={chantier?.nbJoursPrev}
              />
            </div>

            <div>
              <DataFieldColumn
                label="Sondages prev"
                type="text"
                value={chantier?.nbSondagesPrev}
              />
            </div>
          </div>
          <div className="col">
            <div>
              <DataFieldColumn
                label="techniciens"
                type="text"
                value={chantier?.techniciens}
                functionAppliedToValue={(value) => {
                  return value && value.length > 0 ? (
                    <>
                      {value?.map((e) => (
                        <div>{e.initiales}</div>
                      ))}
                    </>
                  ) : (
                    "-"
                  );
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Vehicules"
                type="text"
                value={chantier?.vehicules}
                functionAppliedToValue={(value) => {
                  return value && value.length > 0 ? (
                    <>
                      {value?.map((e) => (
                        <div>{e?.designation}</div>
                      ))}
                    </>
                  ) : (
                    "-"
                  );
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Préparateurs"
                type="text"
                value={chantier?.preparateurs}
                functionAppliedToValue={(value) => {
                  return value && value.length > 0 ? (
                    <>
                      {value?.map((e) => (
                        <div>{e.initiales}</div>
                      ))}
                    </>
                  ) : (
                    "-"
                  );
                }}
              />
            </div>
            <div>
              <DataFieldColumn
                label="Matériels"
                type="text"
                value={chantier?.materielsChantier}
                functionAppliedToValue={(value) => {
                  return value && value.length > 0 ? (
                    <>
                      {value?.map((e) => (
                        <div>{e.designation}</div>
                      ))}
                    </>
                  ) : (
                    "-"
                  );
                }}
              />
            </div>
          </div>

          <div className="col">
            <DataFieldColumn
              label="Commentaire"
              type="text"
              value={chantier?.commentaire}
            />
          </div>
          <div className="col">
            <DataFieldColumn
              label="Zone réservation"
              type="text"
              value={chantier?.zoneReservation}
            />
            <DataFieldColumn
              label="Période"
              type="text"
              value={chantier?.trancheDateReservation}
            />
            <DataFieldColumn
              label="Proposition hôtel"
              type="text"
              value={chantier?.propositionHotel}
            />
            <DataFieldColumn
              label="Localisation hôtel"
              type="text"
              value={chantier?.localisationHotel}
            />
            <DataFieldColumn
              label="Nom hôtel"
              type="text"
              value={chantier?.nomHotel}
            />
            <DataFieldColumn label="Prix" type="text" value={chantier?.prix} />

            <DataFieldColumn
              label="CB"
              type="text"
              value={chantier?.cb}
              functionAppliedToValue={(value) => (value ? value.name : "-")}
            />

            <DataFieldColumn
              label="Montant remboursé"
              type="text"
              value={chantier?.montantRembourse}
            />

            <DataFieldColumn
              label="Petit déjeuner"
              type="text"
              value={chantier?.petitDej}
            />

            <DataFieldColumn
              label="Commentaire hôtel"
              type="text"
              value={chantier?.commentaireHotel}
            />
          </div>
        </div>
      }
    ></Box>
  );

  let box2 = generatePeriodesBox();

  let blocPage =
    chantier?.periodeChantiers?.length > 0 ? (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <div
          className="FlecheGauche col-"
          style={{
            alignContent: "center",
            textAlign: "center",
            flex: "0 0 auto",
          }}
          onClick={() => (page > 1 ? setPage(page - 1) : null)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#000000"
            height="40px"
            width="40px"
            version="1.1"
            id="Capa_1"
            viewBox="0 0 490 490"
          >
            <g>
              <polygon points="332.668,490 82.631,244.996 332.668,0 407.369,76.493 235.402,244.996 407.369,413.507   " />
            </g>
          </svg>
        </div>
        <div
          style={{
            alignContent: "center",
            textAlign: "center",
            flex: "0 0 auto",
          }}
        >
          Période {page}/{chantier?.periodeChantiers?.length}
        </div>

        <div
          className="Fleche droite"
          style={{
            alignContent: "center",
            textAlign: "center",
            flex: "0 0 auto",
          }}
          onClick={() =>
            page < chantier?.periodeChantiers?.length ? setPage(page + 1) : null
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#000000"
            height="40px"
            width="40px"
            version="1.1"
            id="Capa_1"
            viewBox="0 0 490 490"
            transform="rotate(180)"
          >
            <g>
              <polygon points="332.668,490 82.631,244.996 332.668,0 407.369,76.493 235.402,244.996 407.369,413.507 " />
            </g>
          </svg>
        </div>
      </div>
    ) : (
      <div>
        Aucune période : <div>{buttonAddPeriode}</div>
      </div>
    );

  return (
    <div>
      <div>
        <FormWithValidation className="d-flex flex-grow-1" onSubmit={update}>
          {box}
        </FormWithValidation>
      </div>
      {blocPage}
      {chantier?.periodeChantiers?.length > 0 ? (
        <FormWithValidation className="d-flex flex-grow-1" onSubmit={update}>
          {box2}
        </FormWithValidation>
      ) : null}
    </div>
  );
};

export { BlocChantierColonne };

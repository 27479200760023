import React from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as FileSaver from "file-saver";
import produce from "immer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faFileSignature,
  faPlus,
  faPrint,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { DialogForm, ButtonIconWithValidation, ButtonIcon } from "_components";
import { Input } from "_components/Input";
import { App } from "App";

import {
  ContactsClient,
  InformationsClient,
  FinancierClient,
  LogistiqueClient,
  StatistiqueClient,
  Materiel,
  HistoriqueClient,
  TacheClient,
  Affaires,
  LLD,
  Logiciels,
} from ".";

import { WarningBar } from "_components";

import {
  ClientService,
  SageDocumentService,
  EtatClientService,
  FamilleAffaireService,
  ProduitInterneService,
  ActionsTacheService,
  ProcessTacheService,
  TacheService,
  PrioritesTacheService,
  PersonnelService,
  StatutsTacheService,
  ContactService,
  CommandeService,
  DevisService,
  ConsultationService,
  FicheDeSuiviService,
  MaterielService,
  ContratService,
  AffaireService,
  LicencesService,
  ChantierService,
} from "_services";

import { ToLocaleDateString, getIconClient, IfNullToText } from "_utils";
import TabSelector from "_components/FicheComponents/TabSelector";

class FicheClient extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingSynthese: true,
      tabs: [
        "Général",
        "Contacts",
        "Financier",
        "Actions",
        "Matériels",
        "Logiciels",
        //        "FORM",
        "LLD",
        "Logistique",
        "Statistiques",
        "Affaires",
        "Historique",
      ],
      active: "Général",
      editing: false,
      client: {},
      facturesImpayees: [],
      buffer_Pays_France: null,
      idNewAdresse: null,
      historique: {},
      tache: {},
      produitInternes: [],
      statistiquesLicencesClient: null,
      licences: [],
      licencesDistri_SoldByClient: [],
      licencesDistri_SoldToClient: [],
      validGeosprintUser: [],
      validWebsprintUser: [],
      licenceFilters: {
        active: true,
        categorieLogiciel: null,
        withUser: null,
        type: null,
      },
      adresseCopiee: null,
      anneeStatistique: 2,
    };

    this.renderActive = this.renderActive.bind(this);
    this.generateTabs = this.generateTabs.bind(this);
    this.updateContact = this.updateContact.bind(this);
    this.updateHisto = this.updateHisto.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCreateConsultation = this.handleCreateConsultation.bind(this);
    this.handleCreateFdS = this.handleCreateFdS.bind(this);
    this.handleCreateContrat = this.handleCreateContrat.bind(this);

    this.handleEditing = this.handleEditing.bind(this);
    this.addNewAdresseLivraison = this.addNewAdresseLivraison.bind(this);
    this.deleteAdresseLivraison = this.deleteAdresseLivraison.bind(this);
    this.swapWithAdressePrincipale = this.swapWithAdressePrincipale.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleChangeAdresseLivraisonPrincipalePosition =
      this.handleChangeAdresseLivraisonPrincipalePosition.bind(this);
    this.setOpenDialogCreationHisto =
      this.setOpenDialogCreationHisto.bind(this);
    this.setOpenDialogCreationTache =
      this.setOpenDialogCreationTache.bind(this);
    this.setForceUpdateActionTache = this.setForceUpdateActionTache.bind(this);
    this.setForceUpdateActionHistorique =
      this.setForceUpdateActionHistorique.bind(this);

    this.renderDialogUpdateTache = this.renderDialogUpdateTache.bind(this);
    this.handleClickEditionTache = this.handleClickEditionTache.bind(this);
    this.setOpenDialogUpdateTache = this.setOpenDialogUpdateTache.bind(this);

    this.GetDocumentProxy = this.GetDocumentProxy.bind(this);
    this.generateInputDocument = this.generateInputDocument.bind(this);
    this.setForceUpdateInputDocument =
      this.setForceUpdateInputDocument.bind(this);

    this.handleClickAddUserLogiciels =
      this.handleClickAddUserLogiciels.bind(this);
    this.handleClickRemoveUserLogiciels =
      this.handleClickRemoveUserLogiciels.bind(this);
    this.handleClickSuspendAllLicences =
      this.handleClickSuspendAllLicences.bind(this);
    this.handleUpdateLicence = this.handleUpdateLicence.bind(this);
    this.handleSuspendLicence = this.handleSuspendLicence.bind(this);
    this.isInvalidLicenceUser = this.isInvalidLicenceUser.bind(this);
    this.getFilteredLicences = this.getFilteredLicences.bind(this);

    this.bandeauClient = this.bandeauClient.bind(this);
    this.changeTab = this.changeTab.bind(this);

    this.copierAdresse = this.copierAdresse.bind(this);
    this.collerAdresse = this.collerAdresse.bind(this);
  }

  bandeauClient(client) {
    return (
      <div>
        <div className="d-flex m-2">
          <div className="">
            {client.etat && getIconClient(client.etat.qualification, "big")}
          </div>
          <div className="ms-3 text-center-md align-self-center">
            {"Client : " + IfNullToText(client.nom_Client, "")}
          </div>
        </div>
      </div>
    );
  }

  setOpenDialogCreationHisto(openDialogFunc) {
    this.setState({ openDialogCreationHistoFunc: openDialogFunc });
  }

  setForceUpdateInputDocument(callbackFunction) {
    this.setState({ callbackForceUpdateInputDocument: callbackFunction });
  }

  setOpenDialogCreationTache(openDialogFunc) {
    this.setState({ openDialogCreationTacheFunc: openDialogFunc });
  }

  setForceUpdateActionTache(callbackFunction) {
    this.setState({ callbackForceUpdateActionTache: callbackFunction });
  }

  setForceUpdateActionHistorique(callbackFunction) {
    this.setState({ callbackForceUpdateActionHistorique: callbackFunction });
  }

  setOpenDialogUpdateTache(openDialogFunc) {
    this.setState({ openDialogUpdateTacheFunc: openDialogFunc });
  }

  componentDidMount() {
    if (sessionStorage.getItem("ficheClientOnglet") == null) {
      sessionStorage.setItem("ficheClientOnglet", this.state.active);
    } else {
      this.setState({ active: sessionStorage.getItem("ficheClientOnglet") });
    }
    let { id } = this.props.match.params;

    ClientService.getById(id).then((res) =>
      this.setState({ client: res.data, loading: false }, () => {
        SageDocumentService.getFacturesImpayeesByCompteTiers(
          this.state.client.compte_Tier
        ).then((res) => {
          let data = res.data && res.data.datas ? res.data.datas : res.data;
          this.setState((prevState) => ({
            ...prevState,
            facturesImpayees: data,
          }));
        });

        this.getFilteredLicences();
      })
    );

    ClientService.getStatistiquesClient(
      id,
      this.state.produitInternes,
      this.state.anneeStatistique
    ).then((res) => {
      this.setState({ statClient: res.data, loadingSynthese: false });
    });
  }

  GetDocumentProxy(search) {
    if (this.state.tache.typeEntiteReference == "affaires") {
      return AffaireService.getAll({
        size: 25,
        global: search,
        soldee: false,
        nom_Client: this.state?.client?.nom_Client,
        actif: true,
      });
    } else if (this.state.tache.typeEntiteReference == "devis") {
      return DevisService.getAll({
        orderBy: "nom_Devis",
        order: "ASC",
        nom_Client: this.state.client?.nom_Client,
        global: search,
        size: 20,
        actif: true,
      });
    } else if (this.state.tache.typeEntiteReference == "commandes") {
      return CommandeService.getAll({
        orderBy: "nom_Commande",
        order: "ASC",
        num_Client: this.state.client?.id,
        global: search,
        size: 20,
        actif: true,
      });
    } else if (this.state.tache.typeEntiteReference == "consultations") {
      return ConsultationService.getAll({
        orderBy: "nom_Consultation",
        order: "ASC",
        nom_Client: this.state.client?.nom_Client,
        global: search,
        size: 20,
      });
    } else if (this.state.tache.typeEntiteReference == "fichesDeSuivi") {
      return FicheDeSuiviService.getAll({
        orderBy: "reference",
        order: "ASC",
        nom_Client: this.state.client?.nom_Client,
        global: search,
        size: 20,
        actif: true,
      });
    } else if (this.state.tache.typeEntiteReference == "personnels") {
      return PersonnelService.getForSS(search);
    } else if (this.state.tache.typeEntiteReference == "materiels") {
      return MaterielService.getAll({
        size: 25,
        global: search,
        en_Sommeil: false,
        nom_utilisateur: this.state?.client?.nom_client,
      });
    } else if (this.state.tache.typeEntiteReference == "contrats") {
      return ContratService.getAll({
        size: 25,
        global: search,
        nom_Client: this.state?.client?.nom_Client,
        actif: true,
      });
    } else if (this.state.tache.typeEntiteReference == "chantiers") {
      return ChantierService.getAllViaPost({
        size: 25,
        global: search,
        actif: true,
      });
    } else return null;
  }

  generateInputDocument() {
    let propsToPut;
    if (this.state.tache.typeEntiteReference == "affaires") {
      propsToPut = {
        value: this.state.tache.idReference
          ? {
              id: this.state.tache.idReference,
              nomAffaire: "",
            }
          : null,
        label: "Affaires",
        optionFieldToDisplay: [
          "produitInterne.designation",
          "reference",
          "nomAffaire",
        ],
        valueFieldToDisplay: ["reference", "nomAffaire"],
      };
    } else if (this.state.tache.typeEntiteReference == "devis") {
      propsToPut = {
        value: this.state.tache.idReference
          ? {
              id: this.state.tache.idReference,
              nom_Devis: "",
            }
          : null,
        label: "Devis",
        optionFieldToDisplay: ["reference", "nom_Devis"],
        valueFieldToDisplay: ["reference", "nom_Devis"],
      };
    } else if (this.state.tache.typeEntiteReference == "commandes") {
      propsToPut = {
        value: this.state.tache.idReference
          ? {
              id: this.state.tache.idReference,
              nom_Commande: "",
            }
          : null,
        label: "Commandes",
        optionFieldToDisplay: ["reference", "nom_Commande"],
        valueFieldToDisplay: ["reference", "nom_Commande"],
      };
    } else if (this.state.tache.typeEntiteReference == "consultations") {
      propsToPut = {
        value: this.state.tache.idReference
          ? {
              id: this.state.tache.idReference,
              nom_Consultation: "",
            }
          : null,
        label: "Consultation",
        optionFieldToDisplay: ["reference", "nom_Consultation"],
        valueFieldToDisplay: ["reference", "nom_Consultation"],
      };
    } else if (this.state.tache.typeEntiteReference == "fichesDeSuivi") {
      propsToPut = {
        value: this.state.tache.idReference
          ? {
              id: this.state.tache.idReference,
              nomAffaire: "",
            }
          : null,
        label: "Fiche de suivi",
        optionFieldToDisplay: ["reference"],
        valueFieldToDisplay: ["reference"],
      };
    } else if (this.state.tache.typeEntiteReference == "personnels") {
      propsToPut = {
        value: this.state.tache.idReference
          ? {
              id: this.state.tache.idReference,
              nom: "",
              prenom: "",
            }
          : null,
        label: "Personnel",
        optionFieldToDisplay: ["nom", "prenom"],
        valueFieldToDisplay: ["nom", "prenom"],
      };
    } else if (this.state.tache.typeEntiteReference == "materiels") {
      propsToPut = {
        value: this.state.tache.idReference
          ? {
              id: this.state.tache.idReference,
              nomAffaire: "",
            }
          : null,
        label: "Materiel",
        optionFieldToDisplay: ["reference"],
        valueFieldToDisplay: ["reference"],
      };
    } else if (this.state.tache.typeEntiteReference == "contrats") {
      propsToPut = {
        value: this.state.tache.idReference
          ? {
              id: this.state.tache.idReference,
              nomAffaire: "",
            }
          : null,
        label: "Fiche de suivi",
        optionFieldToDisplay: ["reference"],
        valueFieldToDisplay: ["reference"],
      };
    } else if (this.state.tache.typeEntiteReference == "chantiers") {
      propsToPut = {
        value: this.state.tache.idReference
          ? {
              id: this.state.tache.idReference,
              reference: "",
            }
          : null,
        label: "Chantier",
        optionFieldToDisplay: ["affaire.reference", "reference", ,],
        valueFieldToDisplay: ["affaire.reference", "reference"],
      };
    } else return null;

    return (
      <Input
        setForceUpdateFunction={this.setForceUpdateInputDocument}
        accessor="idReference"
        type="selectSearch"
        service={this.GetDocumentProxy}
        handleChange={(accessor, value) => {
          this.setState(
            produce((prevState) => {
              prevState.tache.idReference = value?.id;
            })
          );
        }}
        {...propsToPut}
      />
    );
  }

  /**
   * Permet de mettre a jour la liste des contacts du client
   * @param {*} contact
   * @param {*} typeRequest
   * @param {*} response
   */
  updateContact(contact, typeRequest, response) {
    let contacts = [...this.state.client.contacts];
    let newContact = {};
    let index = 0;

    for (let i = 0; i < contacts.length; i++) {
      if (contacts[i].id === contact.id) {
        index = i;
        break;
      }
    }

    switch (typeRequest) {
      case "post":
        newContact = { ...contact };
        newContact.id = response.data.id;
        contacts.splice(index, 0, newContact);
        break;
      case "link":
        newContact = { ...contact };
        contacts.splice(index, 0, newContact);
        break;
      case "put":
        contacts.splice(index, 1, contact);

        break;
      case "delete":
        contacts.splice(index, 1);
        break;
      default:
        console.log("le type de requete n'est pas encore géré.");
    }

    let client = { ...this.state.client, contacts: contacts };
    this.setState({ client: client });

    this.getFilteredLicences();
  }

  updateHisto(newHistorique) {
    this.setState(
      produce((prevState) => {
        prevState.client.historiques = prevState.client.historiques.concat([
          newHistorique,
        ]);
      })
    );
  }

  updateTache(newTache) {
    this.setState(
      produce((prevState) => {
        prevState.client.taches = prevState.client.taches.concat([newTache]);
      })
    );
  }

  handleCreateConsultation() {
    this.props.history.push({
      pathname: "/consultations/add",
      state: { client: this.state.client },
    });
  }

  handleCreateContrat() {
    this.props.history.push({
      pathname: "/contrats/add",
      state: { client: this.state.client },
    });
  }

  handleCreateFdS(utilisateur, materiel) {
    this.props.history.push({
      pathname: "/fichesDeSuivi/add",
      state: {
        proprietaire: this.state.client,
        utilisateur: utilisateur,
        materiel: materiel,
      },
    });
  }

  handleClickEditionTache(idTache) {
    var tache = this.state.client.taches.find((el) => el.id == idTache);

    this.setState(
      produce((prevState) => {
        prevState.tache = tache;
      })
    );

    this.state.openDialogUpdateTacheFunc?.();
  }

  async handleClickAddUserLogiciels(numLicence, contact) {
    try {
      await LicencesService.addUser(numLicence, contact.id);
      App.Toaster.current?.createToast({
        body: "L'attribution de la licence a réussi",
        header: "Succès",
        type: "success",
      });
    } catch (error) {
      App.Toaster.current?.createToast({
        body: "L'attribution de la licence à l'utilisateur sélectionné a échoué",
        header: "Echec",
        type: "failure",
      });
    }

    await this.getFilteredLicences();
  }

  async handleClickRemoveUserLogiciels(numLicence) {
    try {
      await LicencesService.removeUser(numLicence);
      App.Toaster.current?.createToast({
        body: "La licence n'est plus attribuée à un utilisateur",
        header: "Succès",
        type: "success",
      });
    } catch (error) {
      App.Toaster.current?.createToast({
        body: "Le retrait du droit d'utilisation de la licence accordé à l'utilisateur a échoué",
        header: "Echec",
        type: "failure",
      });
    }

    await this.getFilteredLicences();
  }

  async handleClickSuspendAllLicences() {
    await LicencesService.suspendAllByClient(this.state.client.id);

    await this.getFilteredLicences();
  }

  async handleUpdateLicence(licence) {
    try {
      await LicencesService.updateLicence(licence);
      App.Toaster.current?.createToast({
        body: "Données modifiées avec succès",
        header: "Succès",
        type: "success",
      });
    } catch (error) {
      App.Toaster.current?.createToast({
        body: "Erreur lors de la modification des données",
        header: "Echec",
        type: "failure",
      });
    }

    await this.getFilteredLicences();
  }

  async handleSuspendLicence(licenceId) {
    LicencesService.suspend(licenceId)
      .then(() =>
        App.Toaster.current?.createToast({
          body: "Licence " + licenceId + " supprimée définitivement",
          header: "Succès",
          type: "success",
        })
      )
      .then(() => this.getFilteredLicences());
  }

  /**
   * Permet de mettre a jour l'etat du client sans devoir le recharger
   * @param {*} accessor
   * @param {*} value
   */
  handleChange(accessor, value) {
    if (accessor === "client") {
      //Undo
      this.setState({ client: value });
    } else if (accessor === "compte_Tier") {
      // Cas spécifique : compte tiers.
      if (value.id < 0) {
        // Nouveau compte tier
        this.setState(
          produce((prevState) => {
            prevState.client.cBmarq = value.id;
            prevState.client.compte_Tier = value.compte_Tier;
          })
        );
      } else {
        // Compte tier existant
        this.setState(
          produce((prevState) => {
            prevState.client.cBmarq = value.id;
            prevState.client.compte_Tier = value.compte_Tier;
            prevState.client.devise = value.devise;
            prevState.client.echeance = value.echeance;
            prevState.client.mode_Paiement = value.mode_Paiement;
            prevState.client.code_Tarif = value.code_Tarif;
          })
        );
      }
      if (
        this.state.client?.etat?.qualification == "Suspect" ||
        this.state.client?.etat?.qualification == "Prospect"
      ) {
        EtatClientService.getAll().then((retour) => {
          let etat = retour.data.filter(
            (data) => data.qualification == "Client"
          );
          this.setState(
            produce((prevState) => {
              prevState.client.etat = etat[0];
            })
          );
        });
      }
    } else if (accessor === "produitInternesSynthese") {
      this.setState({ produitInternes: value }, () => {
        ClientService.getSyntheseClient(
          this.state.client.id,
          this.state.produitInternes?.map((e) => e.designation).join("|") ??
            null,
          this.state.anneeStatistique ?? 2
        ).then((res) => {
          this.setState({
            statClient: { ...this.state.statClient, synthese: res.data },
          });
        });
      });
    } else if (accessor === "anneeStatistique") {
      this.setState({ anneeStatistique: value ?? 2 }, () => {
        ClientService.getStatistiquesClient(
          this.state.client.id,
          this.state.produitInternes?.map((e) => e.designation).join("|") ??
            null,
          this.state.anneeStatistique ?? 2
        ).then((res) => {
          this.setState({ statClient: res.data });
        });
      });
    } else {
      //Update
      if (accessor.includes(".")) {
        var accessorSplit = accessor.split(".");

        if (accessorSplit[0] === "adresses_Livraison") {
          // Format adresses_Livraison.id.accessor
          var adresseId = accessorSplit[1];
          var adresseAccessor = accessorSplit[2];

          this.setState(
            produce((prevState) => {
              prevState.client.adresses_Livraison.find(
                (adr) => adr.id == adresseId
              )[adresseAccessor] = value;
            })
          );
        } else if (accessorSplit[0] === "filtre") {
          // Format filtre.accessor
          var acc = accessorSplit[1];

          this.setState(
            produce((prevState) => {
              prevState.licenceFilters[acc] = value;
            }),
            () => this.getFilteredLicences()
          );
        } else {
          // Nested property (separated by a '.')
          this.setState((prevState) => ({
            client: {
              ...prevState.client,
              [accessorSplit[0]]: {
                ...prevState.client[accessorSplit[0]],
                [accessorSplit[1]]: value,
              },
            },
          }));
        }
      } else {
        // Shallow property
        this.setState((prevState) => ({
          client: {
            ...prevState.client,
            [accessor]: value,
          },
        }));
      }
    }
  }

  handleDelete() {
    return ClientService.delete(this.state.client).then(() => {
      this.props.history.push({ pathname: "/clients" });
    });
  }

  handleUpdate() {
    return ClientService.put(this.state.client)
      .then((res) => {
        this.setState({ client: res.data });
      })
      .catch((erreur) => {
        console.log(erreur.response);
        App.Toaster.current?.createToast({
          body: erreur?.response?.data?.cause,
          header: "Echec",
          type: "failure",
        });
      });
  }

  handleEditing(editing) {
    this.setState((prevState) => ({
      ...prevState,
      editing: editing,
      idNewAdresse: 0,
    }));
  }

  changeTab(tab) {
    this.setState({ active: tab });
    sessionStorage.setItem("ficheClientOnglet", tab);
  }

  isInvalidLicenceUser(contact, licence) {
    if (licence.classification.categorie.id == 1)
      return !this.state.validWebsprintUser.some(
        (user) => user.id == contact.id
      );
    else
      return !this.state.validGeosprintUser.some(
        (user) => user.id == contact.id
      );
  }

  async getFilteredLicences() {
    const req1 = LicencesService.getByClient(this.state.client.id, {
      categorieId: this.state.licenceFilters.categorieLogiciel?.id,
      affectee: this.state.licenceFilters.withUser,
      typeLicenceId: this.state.licenceFilters.type?.id,
      active: this.state.licenceFilters.active,
    });
    const req2 = LicencesService.getClientStats(this.state.client.id);
    const req3 = LicencesService.getValidWebsprintUser(this.state.client.id);
    const req4 = LicencesService.getValidGeosprintUser(this.state.client.id);

    const req5 = LicencesService.getSoldByClient(this.state.client.id, {
      categorieId: this.state.licenceFilters.categorieLogiciel?.id,
      affectee: this.state.licenceFilters.withUser,
      typeLicenceId: this.state.licenceFilters.type?.id,
      active: this.state.licenceFilters.active,
    });
    const req6 = LicencesService.getSoldToClient(this.state.client.id, {
      categorieId: this.state.licenceFilters.categorieLogiciel?.id,
      affectee: this.state.licenceFilters.withUser,
      typeLicenceId: this.state.licenceFilters.type?.id,
      active: this.state.licenceFilters.active,
    });

    const [res1, res2, res3, res4, res5, res6] = await Promise.all([
      req1,
      req2,
      req3,
      req4,
      req5,
      req6,
    ]);

    this.setState({
      licences: res1.data.sort((a, b) => a.numero - b.numero),
      statistiquesLicencesClient: res2.data,
      validWebsprintUser: res3.data,
      validGeosprintUser: res4.data,
      licencesDistri_SoldByClient: res5.data,
      licencesDistri_SoldToClient: res6.data,
    });
  }

  addNewAdresseLivraison() {
    this.setState(
      produce((prevState) => {
        if (prevState.client.adresses_Livraison == null) {
          prevState.client.adresses_Livraison = [];
        }
        let newId =
          Math.min(
            ...prevState.client.adresses_Livraison.map((el) => el.id),
            0
          ) - 1;
        prevState.client.adresses_Livraison.push({
          id: newId,
          intitule: "",
          complement: "",
          voie: "",
          ville: "",
          pays: prevState.buffer_Pays_France,
          region: null,
          cp: "",
          lon: 0,
          lat: 0,
          precision: 3,
        });
        prevState.idNewAdresse = newId;
      })
    );
  }

  deleteAdresseLivraison(id) {
    this.setState(
      produce((prevState) => {
        prevState.client.adresses_Livraison =
          prevState.client.adresses_Livraison.filter((adr) => adr.id != id);
        prevState.editing = false;
        prevState.idNewAdresse = 0;
      }),
      this.handleUpdate
    );
  }

  swapWithAdressePrincipale(id) {
    this.setState(
      produce((prevState) => {
        let index = prevState.client.adresses_Livraison.findIndex(
          (adr) => adr.id == id
        );
        [
          prevState.client.adresse_Livraison_Principale.intitule,
          prevState.client.adresses_Livraison[index].intitule,
          prevState.client.adresse_Livraison_Principale.complement,
          prevState.client.adresses_Livraison[index].complement,
          prevState.client.adresse_Livraison_Principale.voie,
          prevState.client.adresses_Livraison[index].voie,
          prevState.client.adresse_Livraison_Principale.ville,
          prevState.client.adresses_Livraison[index].ville,
          prevState.client.adresse_Livraison_Principale.cp,
          prevState.client.adresses_Livraison[index].cp,
          prevState.client.adresse_Livraison_Principale.pays,
          prevState.client.adresses_Livraison[index].pays,
          prevState.client.adresse_Livraison_Principale.region,
          prevState.client.adresses_Livraison[index].region,
          prevState.client.adresse_Livraison_Principale.lon,
          prevState.client.adresses_Livraison[index].lon,
          prevState.client.adresse_Livraison_Principale.lat,
          prevState.client.adresses_Livraison[index].lat,
          prevState.client.adresse_Livraison_Principale.precision,
          prevState.client.adresses_Livraison[index].precision,
          prevState.client.adresse_Livraison_Principale.nomContact,
          prevState.client.adresses_Livraison[index].nomContact,
          prevState.client.adresse_Livraison_Principale.telephone,
          prevState.client.adresses_Livraison[index].telephone,
          prevState.client.adresse_Livraison_Principale.fax,
          prevState.client.adresses_Livraison[index].fax,
          prevState.client.adresse_Livraison_Principale.email,
          prevState.client.adresses_Livraison[index].email,
        ] = [
          prevState.client.adresses_Livraison[index].intitule,
          prevState.client.adresse_Livraison_Principale.intitule,
          prevState.client.adresses_Livraison[index].complement,
          prevState.client.adresse_Livraison_Principale.complement,
          prevState.client.adresses_Livraison[index].voie,
          prevState.client.adresse_Livraison_Principale.voie,
          prevState.client.adresses_Livraison[index].ville,
          prevState.client.adresse_Livraison_Principale.ville,
          prevState.client.adresses_Livraison[index].cp,
          prevState.client.adresse_Livraison_Principale.cp,
          prevState.client.adresses_Livraison[index].pays,
          prevState.client.adresse_Livraison_Principale.pays,
          prevState.client.adresses_Livraison[index].region,
          prevState.client.adresse_Livraison_Principale.region,
          prevState.client.adresses_Livraison[index].lon,
          prevState.client.adresse_Livraison_Principale.lon,
          prevState.client.adresses_Livraison[index].lat,
          prevState.client.adresse_Livraison_Principale.lat,
          prevState.client.adresses_Livraison[index].precision,
          prevState.client.adresse_Livraison_Principale.precision,
          prevState.client.adresses_Livraison[index].nomContact,
          prevState.client.adresse_Livraison_Principale.nomContact,
          prevState.client.adresses_Livraison[index].telephone,
          prevState.client.adresse_Livraison_Principale.telephone,
          prevState.client.adresses_Livraison[index].fax,
          prevState.client.adresse_Livraison_Principale.fax,
          prevState.client.adresses_Livraison[index].email,
          prevState.client.adresse_Livraison_Principale.email,
        ];
      }),
      this.handleUpdate
    );
  }

  handleChangeAdresseLivraisonPrincipalePosition(
    latitude,
    longitude,
    precision
  ) {
    this.setState(
      produce((prevState) => {
        prevState.client.adresse_Siege_Social.lat = latitude;
        prevState.client.adresse_Siege_Social.lon = longitude;
        prevState.client.adresse_Siege_Social.precision = precision;
      })
    );
  }

  copierAdresse(accessor) {
    this.state.adresseCopiee = this.state.client[accessor];
  }

  collerAdresse(accessor) {
    if (this.state.adresseCopiee != null) {
      if (accessor.includes(".")) {
        var accessorSplit = accessor.split(".");

        if (accessorSplit[0] === "adresses_Livraison") {
          // Format adresses_Livraison.id.accessor
          var adresseId = accessorSplit[1];

          this.setState(
            produce((prevState) => {
              var adresseARemplace = prevState.client.adresses_Livraison.find(
                (adr) => adr.id == adresseId
              );
              var adresseACopie = prevState.adresseCopiee;

              adresseARemplace.intitule = adresseACopie.intitule;
              adresseARemplace.complement = adresseACopie.complement;
              adresseARemplace.voie = adresseACopie.voie;
              adresseARemplace.ville = adresseACopie.ville;
              adresseARemplace.pays = adresseACopie.pays;
              adresseARemplace.region = adresseACopie.region;
              adresseARemplace.cp = adresseACopie.cp;
              adresseARemplace.lon = adresseACopie.lon;
              adresseARemplace.lat = adresseACopie.lat;
              adresseARemplace.precision = adresseACopie.precision;
            })
          );
        }
      } else {
        this.setState(
          produce((prevState) => {
            var adresseARemplace = prevState.client[accessor];
            var adresseACopie = prevState.adresseCopiee;

            adresseARemplace.intitule = adresseACopie.intitule;
            adresseARemplace.complement = adresseACopie.complement;
            adresseARemplace.voie = adresseACopie.voie;
            adresseARemplace.ville = adresseACopie.ville;
            adresseARemplace.pays = adresseACopie.pays;
            adresseARemplace.region = adresseACopie.region;
            adresseARemplace.cp = adresseACopie.cp;
            adresseARemplace.lon = adresseACopie.lon;
            adresseARemplace.lat = adresseACopie.lat;
            adresseARemplace.precision = adresseACopie.precision;
          })
        );
      }
    }
  }

  /**
   * Génère les onglets de la fiche
   * @param {*} tabs
   * @returns
   */
  generateTabs(tabs) {
    return (
      <div>
        <TabSelector
          tabs={tabs}
          activeTab={this.state.active}
          onSelect={(tab) => {
            this.setState({ active: tab });
            sessionStorage.setItem("ficheClientOnglet", tab);
          }}
          notAllowed={this.state.editing}
        ></TabSelector>
        <div>
          <div>
            <DialogForm
              tooltip="Ajouter un historique"
              classNameButton="btn btn-success"
              dialogTitle="Ajouter un historique"
              labelValidateButton="Valider"
              setOpenDialog={this.setOpenDialogCreationHisto}
              onValidate={() => {
                ClientService.postHistorique(
                  this.state.client.id,
                  this.state.historique
                ).then((res) => {
                  this.updateHisto(res.data);
                  this.setState({ historique: {} });
                });
              }}
              onClose={() => {}}
              body={
                <div
                  id="PopupCommande"
                  className={"col-lg-12 row"}
                  style={{ minWidth: "1000px" }}
                >
                  <div className={"col-lg-6"}>
                    <Input
                      value={this.state.historique.process}
                      label="Process"
                      accessor="process"
                      type="selectSearch"
                      service={ProcessTacheService.getAll}
                      showClearButton={false}
                      optionFieldToDisplay={["designation"]}
                      valueFieldToDisplay={["designation"]}
                      handleChange={(accessor, value) => {
                        this.setState(
                          produce((prevState) => {
                            if (prevState.historique.process != value) {
                              prevState.historique.action = null;
                            }
                            prevState.historique.process = value;
                          }),
                          this.state.callbackForceUpdateActionHistorique
                        );
                      }}
                    />

                    <Input
                      value={this.state.historique.action}
                      label="Action"
                      accessor="action"
                      type="selectSearch"
                      service={() =>
                        ActionsTacheService.getAll({
                          processId: this.state.historique.process?.id,
                        })
                      }
                      showClearButton={false}
                      optionFieldToDisplay={["designation"]}
                      optionFieldToReturn={"designation"}
                      valueFieldToDisplay={["designation"]}
                      handleChange={(accessor, value) => {
                        this.setState(
                          produce((prevState) => {
                            prevState.historique.action = value;
                            if (value?.process != null) {
                              prevState.historique.process = value?.process;
                            }
                          })
                        );
                      }}
                      setForceUpdateFunction={
                        this.setForceUpdateActionHistorique
                      }
                    />
                  </div>
                  <div className={"col-lg-6"}>
                    <Input
                      value={this.state.historique.produitInterne}
                      type="selectSearch"
                      accessor="produitInterne"
                      label="Produit Interne"
                      service={ProduitInterneService.getAll}
                      showClearButton={false}
                      optionFieldToDisplay={["designation"]}
                      valueFieldToDisplay={["designation"]}
                      handleChange={(accessor, value) => {
                        this.setState(
                          produce((prevState) => {
                            prevState.historique.produitInterne = value;
                          })
                        );
                      }}
                    />
                    <Input
                      value={this.state.historique.familleAffaire}
                      accessor="familleAffaire"
                      type="selectSearch"
                      label="Famille d'affaire"
                      service={FamilleAffaireService.getAll}
                      showClearButton={false}
                      optionFieldToDisplay={["designation"]}
                      valueFieldToDisplay={["designation"]}
                      handleChange={(accessor, value) => {
                        this.setState(
                          produce((prevState) => {
                            prevState.historique.familleAffaire = value;
                          })
                        );
                      }}
                    />
                    <Input
                      value={this.state.historique.contact}
                      accessor="contact"
                      type="selectSearch"
                      label="Contact"
                      options={this.state.client.contacts}
                      showClearButton={false}
                      optionFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                      valueFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                      handleChange={(accessor, value) => {
                        this.setState(
                          produce((prevState) => {
                            prevState.historique.contact = value;
                          })
                        );
                      }}
                    />
                  </div>
                  <div className={"col-lg-12"}>
                    <Input
                      value={this.state.historique.description}
                      accessor="description"
                      type="textArea"
                      label="Description"
                      handleChange={(accessor, value) => {
                        this.setState(
                          produce((prevState) => {
                            prevState.historique.description = value;
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              }
            />
            <DialogForm
              tooltip="Ajouter une tache"
              classNameButton="btn btn-success"
              dialogTitle="Ajouter une tache"
              labelValidateButton="Valider"
              setOpenDialog={this.setOpenDialogCreationTache}
              onValidate={() => {
                TacheService.postTache({
                  ...this.state.tache,
                  client: this.state.client,
                }).then((res) => {
                  this.updateTache(res.data);
                  this.setState({ tache: {} });
                });
              }}
              onClose={() => {}}
              body={
                <div
                  id="PopupCommande"
                  className={"col-lg-12 row"}
                  style={{ minWidth: "1000px" }}
                >
                  <div className={"col-lg-6"}>
                    <Input
                      value={this.state.tache.process}
                      label="Process"
                      accessor="process"
                      type="selectSearch"
                      service={ProcessTacheService.getAll}
                      showClearButton={false}
                      optionFieldToDisplay={["designation"]}
                      valueFieldToDisplay={["designation"]}
                      handleChange={(accessor, value) => {
                        this.setState(
                          produce((prevState) => {
                            if (prevState.tache.process != value) {
                              prevState.tache.action = null;
                            }
                            prevState.tache.process = value;
                          }),
                          this.state.callbackForceUpdateActionTache
                        );
                      }}
                    />

                    <Input
                      value={this.state.tache.action}
                      label="Action"
                      accessor="action"
                      type="selectSearch"
                      service={() =>
                        ActionsTacheService.getAll({
                          processId: this.state.tache.process?.id,
                        })
                      }
                      showClearButton={false}
                      optionFieldToDisplay={["designation"]}
                      valueFieldToDisplay={["designation"]}
                      handleChange={(accessor, value) => {
                        this.setState(
                          produce((prevState) => {
                            prevState.tache.action = value;
                            if (value?.process != null) {
                              prevState.tache.process = value?.process;
                            }
                          })
                        );
                      }}
                      setForceUpdateFunction={this.setForceUpdateActionTache}
                    />

                    <Input
                      value={this.state.tache.produitInterne}
                      type="selectSearch"
                      accessor="produitInterne"
                      label="Produit Interne"
                      service={ProduitInterneService.getAll}
                      showClearButton={false}
                      optionFieldToDisplay={["designation"]}
                      valueFieldToDisplay={["designation"]}
                      handleChange={(accessor, value) => {
                        this.setState(
                          produce((prevState) => {
                            prevState.tache.produitInterne = value;
                          })
                        );
                      }}
                    />
                    <Input
                      value={this.state.tache.familleAffaire}
                      accessor="familleAffaire"
                      type="selectSearch"
                      label="Famille d'affaire"
                      service={FamilleAffaireService.getAll}
                      showClearButton={false}
                      optionFieldToDisplay={["designation"]}
                      valueFieldToDisplay={["designation"]}
                      handleChange={(accessor, value) => {
                        this.setState(
                          produce((prevState) => {
                            prevState.tache.familleAffaire = value;
                          })
                        );
                      }}
                    />
                  </div>
                  <div className={"col-lg-6"}>
                    <Input
                      value={this.state.tache.contact}
                      accessor="contact"
                      type="selectSearch"
                      label="Contact"
                      options={this.state.client.contacts}
                      showClearButton={false}
                      optionFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                      valueFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                      handleChange={(accessor, value) => {
                        this.setState(
                          produce((prevState) => {
                            prevState.tache.contact = value;
                          })
                        );
                      }}
                      setForceUpdateFunction={this.setForceUpdateContact}
                    />
                    <Input
                      accessor="priorite"
                      value={this.state.tache.priorite}
                      type="selectSearch"
                      label="Priorité"
                      service={PrioritesTacheService.getAll}
                      valueFieldToDisplay={"designation"}
                      optionFieldToDisplay={"designation"}
                      handleChange={(accessor, value) => {
                        this.setState(
                          produce((prevState) => {
                            prevState.tache.priorite = value;
                          })
                        );
                      }}
                    />
                    <Input
                      accessor="dateEcheance"
                      value={this.state.tache.dateEcheance}
                      type="date"
                      label="Date d'écheance"
                      handleChange={(accessor, value) => {
                        this.setState(
                          produce((prevState) => {
                            prevState.tache.dateEcheance = value;
                          })
                        );
                      }}
                    />
                    <Input
                      accessor="typeEntiteReference"
                      value={
                        this.state.tache
                          ? {
                              value: this.state.tache?.typeEntiteReference,
                              id: 0,
                            }
                          : null
                      }
                      type="selectSearch"
                      label="Type de document"
                      options={[
                        {
                          value: "affaires",
                          id: 1,
                        },
                        {
                          value: "devis",
                          id: 2,
                        },
                        {
                          value: "commandes",
                          id: 3,
                        },
                        {
                          value: "fichesDeSuivi",
                          id: 4,
                        },
                        {
                          value: "consultations",
                          id: 6,
                        },
                        {
                          value: "materiels",
                          id: 7,
                        },
                        {
                          value: "contrats",
                          id: 8,
                        },
                        // {
                        //   value: "chantiers",
                        //   id: 8,
                        // },
                      ]}
                      valueFieldToDisplay={"value"}
                      optionFieldToDisplay={"value"}
                      handleChange={(accessor, value) => {
                        this.setState(
                          produce((prevState) => {
                            prevState.tache.typeEntiteReference = value?.value;
                          }),
                          this.state.callbackForceUpdateInputDocument
                        );
                      }}
                    />
                    {this.generateInputDocument()}
                  </div>
                  <div className={"col-lg-12"}>
                    <Input
                      value={this.state.tache.commentaire}
                      accessor="commentaire"
                      type="textArea"
                      label="Commentaire"
                      handleChange={(accessor, value) => {
                        this.setState(
                          produce((prevState) => {
                            prevState.tache.commentaire = value;
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   * Rend la page correspondate à l'onglet sélectionné
   * @returns
   */
  renderActive() {
    switch (this.state.active) {
      case "Général":
        return (
          <InformationsClient
            client={this.state.client}
            stateFieldNameToUpdate="client"
            handleChange={this.handleChange}
            history={this.props.history}
            service={ClientService}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            handleChangeAdresseLivraisonPrincipalePosition={
              this.handleChangeAdresseLivraisonPrincipalePosition
            }
            handleUpdate={this.handleUpdate}
            copierAdresse={this.copierAdresse}
          ></InformationsClient>
        );
      case "Contacts":
        return (
          <ContactsClient
            history={this.props.history}
            client={this.state.client}
            licences={this.state.licences}
            updateContact={this.updateContact}
            changeTab={this.changeTab}
            adresseAColler={this.state.adresseCopiee}
            handleUpdate={this.handleUpdate}
          ></ContactsClient>
        );
      case "Financier":
        return (
          <FinancierClient
            client={this.state.client}
            stateFieldNameToUpdate="client"
            handleChange={this.handleChange}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            service={ClientService}
            handleSetCompteTiers={this.handleSetCompteTiers}
            facturesImpayees={this.state.facturesImpayees}
            collerAdresse={this.collerAdresse}
            handleUpdate={this.handleUpdate}
          ></FinancierClient>
        );
      case "Actions":
        return (
          <TacheClient
            client={this.state.client}
            openDialogCreationTacheFunc={this.state.openDialogCreationTacheFunc}
            handleClickEditionTache={this.handleClickEditionTache}
          />
        );
      case "Matériels":
        return (
          <Materiel
            client={this.state.client}
            stateFieldNameToUpdate="client"
            handleChange={this.handleChange}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            service={ClientService}
            handleSetCompteTiers={this.handleSetCompteTiers}
            handleCreateFdS={this.handleCreateFdS}
            handleUpdate={this.handleUpdate}
          />
        );
      case "Logistique":
        return (
          <LogistiqueClient
            client={this.state.client}
            stateFieldNameToUpdate="client"
            handleChange={this.handleChange}
            service={ClientService}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            addNewAdresseLivraison={this.addNewAdresseLivraison}
            deleteAdresseLivraison={this.deleteAdresseLivraison}
            swapWithAdressePrincipale={this.swapWithAdressePrincipale}
            idNewAdresse={this.state.idNewAdresse}
            collerAdresse={this.collerAdresse}
            handleUpdate={this.handleUpdate}
          ></LogistiqueClient>
        );
      case "Statistiques":
        return (
          <StatistiqueClient
            client={this.state.client}
            stateFieldNameToUpdate="client"
            handleChange={this.handleChange}
            service={ClientService}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            statClient={this.state.statClient}
            produitInternes={this.state.produitInternes}
            loadingSynthese={this.state.loadingSynthese}
            anneeStatistique={this.state.anneeStatistique}
          ></StatistiqueClient>
        );
      case "Affaires":
        return (
          <Affaires
            client={this.state.client}
            openDialogCreationHistoFunc={this.state.openDialogCreationHistoFunc}
          />
        );
      case "LLD":
        return <LLD client={this.state.client} />;
      case "Historique":
        return (
          <HistoriqueClient
            client={this.state.client}
            openDialogCreationHistoFunc={this.state.openDialogCreationHistoFunc}
          />
        );
      case "Logiciels":
        return (
          <Logiciels
            client={this.state.client}
            licences={this.state.licences}
            licencesDistri_SoldByClient={this.state.licencesDistri_SoldByClient}
            licencesDistri_SoldToClient={
              this.state.client?.compte_Tier == "CTEST"
                ? []
                : this.state.licencesDistri_SoldToClient
            }
            statsClient={this.state.statistiquesLicencesClient}
            history={this.props.history}
            filters={this.state.licenceFilters}
            handleChange={this.handleChange}
            handleClickAddUserLogiciels={this.handleClickAddUserLogiciels}
            handleClickRemoveUserLogiciels={this.handleClickRemoveUserLogiciels}
            handleClickSuspendAllLicences={this.handleClickSuspendAllLicences}
            handleUpdateLicence={this.handleUpdateLicence}
            handleSuspend={this.handleSuspendLicence}
            getFilteredLicences={this.getFilteredLicences}
            isInvalidLicenceUser={this.isInvalidLicenceUser}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            handleUpdate={this.handleUpdate}
          />
        );
      default:
        return (
          <h4 className="mt-5">
            Désolé, cette page n'est pas encore disponible.
          </h4>
        );
    }
  }

  renderDialogUpdateTache() {
    const isAnAutomaticTask = this.state.tache.type != null;
    return (
      <DialogForm
        tooltip="Editer une tache"
        classNameButton="btn btn-success"
        dialogTitle="Editer une tache"
        labelValidateButton="Valider"
        setOpenDialog={this.setOpenDialogUpdateTache}
        onValidate={() => {
          TacheService.putTache(this.state.tache)
            .then((res) => {
              this.setState(
                produce((prevState) => {
                  let tacheindex = prevState.client.taches.findIndex(
                    (el) => el.id == this.state.tache.id
                  );
                  prevState.client.taches[tacheindex] = res.data;
                  prevState.tache = {};
                })
              );
            })
            .catch(() =>
              App.Toaster.current?.createToast({
                body: "La modification de la tâche a échouée. Cette tâche a été modifiée entre temps par un autre utilisateur",
                header: "Echec",
                type: "failure",
              })
            );
        }}
        onClose={() => this.setState({ tache: {} })}
        body={
          <div
            id="PopupCommande"
            className={"row"}
            style={{ minWidth: "1000px" }}
          >
            <div className={"col-lg-3"}>
              <div className="pt-2">
                <Input
                  value={this.state.tache.statut}
                  label="Statut"
                  accessor="statut"
                  type="selectSearch"
                  service={StatutsTacheService.getAll}
                  optionFieldToDisplay={["designation"]}
                  valueFieldToDisplay={["designation"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.tache.statut = value;
                      })
                    );
                  }}
                />
              </div>
              <div className="pt-2">
                <Input
                  value={this.state.tache.action}
                  label="Action"
                  accessor="action"
                  type="selectSearch"
                  service={() =>
                    ActionsTacheService.getAll({
                      processId: this.state.tache.process?.id,
                    })
                  }
                  optionFieldToDisplay={["designation"]}
                  valueFieldToDisplay={["designation"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.tache.action = value;
                        if (value?.process != null) {
                          prevState.tache.process = value?.process;
                        }
                      })
                    );
                  }}
                  setForceUpdateFunction={this.setForceUpdateActionTache}
                  disabled={isAnAutomaticTask}
                  showClearButton={!isAnAutomaticTask}
                />
              </div>
              <div className="pt-2">
                <Input
                  accessor="dateEcheance"
                  value={this.state.tache.dateEcheance}
                  type="date"
                  label="Date d'écheance"
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.tache.dateEcheance = value;
                      })
                    );
                  }}
                />
              </div>
              <div className="pt-2">
                <Input
                  accessor="priorite"
                  value={this.state.tache.priorite}
                  type="selectSearch"
                  label="Priorité"
                  service={PrioritesTacheService.getAll}
                  valueFieldToDisplay={"designation"}
                  optionFieldToDisplay={"designation"}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.tache.priorite = value;
                      })
                    );
                  }}
                />
              </div>
            </div>
            <div className={"col-lg-3"}>
              <div className="pt-2">
                <div class="text-uppercase text-muted">Date de création</div>
                <div class="py-2 px-2">
                  {ToLocaleDateString(this.state.tache.dateCreation) ?? "-"}
                </div>
              </div>
              <div className="pt-2">
                <div class="text-uppercase text-muted">Document</div>

                {isAnAutomaticTask ? (
                  <>
                    <div class="py-2 px-2">
                      {this.state.tache.typeEntiteReference +
                        "/" +
                        this.state.tache.idReference}
                      <ButtonIcon
                        icon={faExternalLinkAlt}
                        className="btn btn-secondary btn-sm text-light ms-1 py-0 px-1"
                        onClick={() =>
                          window.open(
                            "/" +
                              this.state.tache.typeEntiteReference +
                              "/" +
                              this.state.tache.idReference,
                            "_blank"
                          )
                        }
                      ></ButtonIcon>
                    </div>
                  </>
                ) : (
                  <Input
                    accessor="typeEntiteReference"
                    value={
                      this.state.tache
                        ? {
                            value: this.state.tache?.typeEntiteReference,
                            id: 0,
                          }
                        : null
                    }
                    type="selectSearch"
                    options={[
                      {
                        value: "affaires",
                        id: 1,
                      },
                      {
                        value: "devis",
                        id: 2,
                      },
                      {
                        value: "commandes",
                        id: 3,
                      },
                      {
                        value: "fichesDeSuivi",
                        id: 4,
                      },
                      {
                        value: "consultations",
                        id: 6,
                      },
                      {
                        value: "materiels",
                        id: 7,
                      },
                      {
                        value: "contrats",
                        id: 8,
                      },
                      {
                        value: "personnels",
                        id: 9,
                      },
                    ]}
                    valueFieldToDisplay={"value"}
                    optionFieldToDisplay={"value"}
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.tache.typeEntiteReference = value?.value;
                        }),
                        this.state.callbackForceUpdateInputDocument
                      );
                    }}
                  />
                )}
              </div>
              {isAnAutomaticTask ? null : (
                <div className="pt-2">{this.generateInputDocument()}</div>
              )}
              <div className="pt-2">
                <div class="text-uppercase text-muted">Retard</div>
                <div class="py-2 px-2">
                  {this.state.tache.joursRetard
                    ? this.state.tache.joursRetard + "j"
                    : "-"}
                </div>
              </div>
              <div className="pt-2">
                <div class="text-uppercase text-muted">Date de fin</div>
                <div class="py-2 px-2">
                  {ToLocaleDateString(this.state.tache.dateFin) ?? "-"}
                </div>
              </div>
            </div>
            <div className={"col-lg-3"}>
              <div className="pt-2">
                <div class="text-uppercase text-muted">Client</div>
                <div class="py-2 px-2">
                  {this.state.tache.client?.nom_Client ?? "-"}
                </div>
              </div>
              <div className="pt-2">
                <Input
                  value={this.state.tache.produitInterne}
                  type="selectSearch"
                  accessor="produitInterne"
                  label="Produit Interne"
                  service={ProduitInterneService.getAll}
                  optionFieldToDisplay={["designation"]}
                  valueFieldToDisplay={["designation"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.tache.produitInterne = value;
                      })
                    );
                  }}
                  disabled={isAnAutomaticTask}
                  showClearButton={!isAnAutomaticTask}
                />
              </div>
              <div className="pt-2">
                <Input
                  value={this.state.tache.familleAffaire}
                  accessor="familleAffaire"
                  type="selectSearch"
                  label="Famille d'affaire"
                  service={FamilleAffaireService.getAll}
                  optionFieldToDisplay={["designation"]}
                  valueFieldToDisplay={["designation"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.tache.familleAffaire = value;
                      })
                    );
                  }}
                  disabled={isAnAutomaticTask}
                  showClearButton={!isAnAutomaticTask}
                />
              </div>
            </div>
            <div className={"col-lg-3"}>
              <div className="pt-2">
                <Input
                  value={this.state.tache.contact}
                  accessor="contact"
                  type="selectSearch"
                  label="Contact"
                  service={() =>
                    ContactService.getByIdClient(this.state.tache.client?.id)
                  }
                  optionFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                  valueFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.tache.contact = value;
                      })
                    );
                  }}
                  setForceUpdateFunction={this.setForceUpdateContact}
                />
              </div>
              <div className="pt-2">
                <Input
                  value={this.state.tache.assigne}
                  label="Assigné"
                  accessor="assigne"
                  type="selectSearch"
                  placeholder=""
                  service={PersonnelService.getForSelectSearch}
                  valueFieldToDisplay={["nom", "prenom"]}
                  optionFieldToDisplay={["nom", "prenom"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.tache.assigne = value;
                      }),
                      this.state.callbackForceUpdateActionTache
                    );
                  }}
                />
              </div>
              <div className="pt-2">
                <Input
                  value={this.state.tache.process}
                  label="Process"
                  accessor="process"
                  type="selectSearch"
                  service={ProcessTacheService.getAll}
                  optionFieldToDisplay={["designation"]}
                  valueFieldToDisplay={["designation"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        if (prevState.tache.process != value) {
                          prevState.tache.action = null;
                        }
                        prevState.tache.process = value;
                      }),
                      this.state.callbackForceUpdateActionTache
                    );
                  }}
                  disabled={isAnAutomaticTask}
                  showClearButton={!isAnAutomaticTask}
                />
              </div>
            </div>

            <div className="col-lg-12 pt-2">
              <Input
                value={this.state.tache.commentaire}
                accessor="commentaire"
                type="textArea"
                label="Commentaire"
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.tache.commentaire = value;
                    })
                  );
                }}
              />
            </div>
          </div>
        }
      />
    );
  }

  render() {
    const isClientEmpty = !Object.entries(this.state.client).length;
    let color = {
      Client: { backgroundColor: "#31b02b" },
      Abonné: { backgroundColor: "#9831c6" },
      Distributeur: { backgroundColor: "#3f3f3f" },
      Suspect: { backgroundColor: "#c73044" },
      Prospect: { backgroundColor: "#c7832f" },
    };
    return (
      <>
        <Helmet>
          <title>
            {"Client : ".concat(
              this.state.client?.nom_Client ? this.state.client.nom_Client : ""
            )}
          </title>
        </Helmet>
        {this.renderDialogUpdateTache()}
        {this.state.loading ? (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
          </div>
        ) : (
          <div className=" p-3 m-4 rounded" id="ficheClient">
            <div className="d-flex overflow-auto justify-content-between py-1">
              <h4 className="nowrap pr-100">
                {this.bandeauClient(this.state.client)}
              </h4>
              <div className="d-flex" style={{ alignItems: "center" }}>
                <Link
                  to="/clients"
                  className="btn btn-primary nowrap ms-1 solwayFont"
                >
                  Base Client
                </Link>
                <ButtonIcon
                  icon={faPlus}
                  onClick={this.handleCreateContrat}
                  className="btn btn-success text-light ms-1"
                  tooltip="Créer un contrat LLD pour ce client"
                ></ButtonIcon>
                <ButtonIcon
                  icon={faFileSignature}
                  onClick={this.handleCreateConsultation}
                  className="btn btn-success text-light ms-1"
                  tooltip="Créer une fiche de consultation"
                ></ButtonIcon>
                <ButtonIcon
                  icon={faPrint}
                  className="btn btn-success text-light ms-1"
                  tooltip="Impression fiche client"
                  onClick={() => {
                    ClientService.printFicheClient(this.state.client.id).then(
                      (res) => {
                        var blob = new Blob([res?.data], {
                          type: "application/pdf;base64",
                        });
                        FileSaver.saveAs(
                          blob,
                          this.state.client.nom_Client + ".pdf"
                        );
                      }
                    );
                  }}
                />
                {App.RightsGuard.current?.hasRight("Client", "Suppression") ==
                "RW" ? (
                  <ButtonIconWithValidation
                    icon={faTrash}
                    onClick={this.handleDelete}
                    className="btn btn-danger text-light ms-1"
                    alertTitle=" Suppression"
                    alertBody="Souhaitez-vous réellement supprimer ce client ?"
                    alertButtonValidationText="Oui, je veux supprimer."
                  ></ButtonIconWithValidation>
                ) : null}
                {/* {this.props.history.length > 1 && (
                  <button
                    className="btn btn-primary nowrap ms-1 solwayFont"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Retour
                  </button>
                )} */}
              </div>
            </div>
            {this.generateTabs(this.state.tabs)}
            <WarningBar
              active={false}
              content={this.state.client?.etat?.qualification}
              style={color[this.state.client?.etat?.qualification]}
              background={""}
            />
            <WarningBar
              active={!this.state.client.litige}
              content={"Ce client est en litige"}
              background={"bg-danger"}
            />
            <WarningBar
              active={this.state.client.actif}
              content={"Ce client n'est pas actif"}
            />
            <WarningBar
              active={!this.state.client.ficheSuiviActive}
              content={"Ce client a une fiche de suivi en cours"}
            />

            {!isClientEmpty && this.renderActive()}
          </div>
        )}
      </>
    );
  }
}

/**
 * Validation des props :
 */
FicheClient.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export { FicheClient };
